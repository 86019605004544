import {CountyDefinition} from "../../../commontypes/types";


export const countyDefinitions:CountyDefinition[] =
  [
    {
      key: "01",
      label: "Atlantic",
      active: true,
      municipalities: [
    {
      key: "0101",
      label: "Absecon City"
    },
    {
      key: "0102",
      label: "Atlantic City"
    },
    {
      key: "0103",
      label: "Brigantine City"
    },
    {
      key: "0104",
      label: "Buena Boro"
    },
    {
      key: "0105",
      label: "Buena Vista Twp"
    },
    {
      key: "0106",
      label: "Corbin City"
    },
    {
      key: "0107",
      label: "Egg Harbor City"
    },
    {
      key: "0108",
      label: "Egg Harbor Twp"
    },
    {
      key: "0109",
      label: "Estell Manor City"
    },
    {
      key: "0110",
      label: "Folsom Boro"
    },
    {
      key: "0111",
      label: "Galloway Twp"
    },
    {
      key: "0112",
      label: "Hamilton Twp"
    },
    {
      key: "0113",
      label: "Hammonton Town"
    },
    {
      key: "0114",
      label: "Linwood City"
    },
    {
      key: "0115",
      label: "Longport Boro"
    },
    {
      key: "0116",
      label: "Margate City"
    },
    {
      key: "0117",
      label: "Mullica Twp"
    },
    {
      key: "0118",
      label: "Northfield City"
    },
    {
      key: "0119",
      label: "Pleasantville City"
    },
    {
      key: "0120",
      label: "Port Republic City"
    },
    {
      key: "0121",
      label: "Somers Point City"
    },
    {
      key: "0122",
      label: "Ventnor City"
    },
    {
      key: "0123",
      label: "Weymouth Twp"
    }

      ]
    },
    {
      key: "02",
      label: "Bergen",
      active: false,
      municipalities: [
    {
      key: "0201",
      label: "ALLENDALE Boro"
    },
    {
      key: "0202",
      label: "ALPINE Boro"
    },
    {
      key: "0203",
      label: "BERGENFIELD Boro"
    },
    {
      key: "0204",
      label: "BOGOTA Boro"
    },
    {
      key: "0205",
      label: "CARLSTADT Boro"
    },
    {
      key: "0206",
      label: "CLIFFSIDE PARK Boro"
    },
    {
      key: "0207",
      label: "CLOSTER Boro"
    },
    {
      key: "0208",
      label: "CRESSKILL Boro"
    },
    {
      key: "0209",
      label: "DEMAREST Boro"
    },
    {
      key: "0210",
      label: "DUMONT Boro"
    },
    {
      key: "0211",
      label: "ELMWOOD PARK Boro"
    },
    {
      key: "0212",
      label: "E RUTHERFORD Boro"
    },
    {
      key: "0213",
      label: "EDGEWATER Boro"
    },
    {
      key: "0214",
      label: "EMERSON Boro"
    },
    {
      key: "0215",
      label: "ENGLEWOOD City"
    },
    {
      key: "0216",
      label: "ENGLEWOOD CLIFFS Boro"
    },
    {
      key: "0217",
      label: "FAIRLAWN Boro"
    },
    {
      key: "0218",
      label: "FAIRVIEW Boro"
    },
    {
      key: "0219",
      label: "FORT LEE Boro"
    },
    {
      key: "0220",
      label: "FRANKLIN LAKES Boro"
    },
    {
      key: "0221",
      label: "GARFIELD City"
    },
    {
      key: "0222",
      label: "GLEN ROCK Boro"
    },
    {
      key: "0223",
      label: "HACKENSACK City"
    },
    {
      key: "0224",
      label: "HARRINGTON PARK Boro"
    },
    {
      key: "0225",
      label: "HASBROUCK HGHTS Boro"
    },
    {
      key: "0226",
      label: "HAWORTH Boro"
    },
    {
      key: "0227",
      label: "HILLSDALE Boro"
    },
    {
      key: "0228",
      label: "HOHOKUS Boro"
    },
    {
      key: "0229",
      label: "LEONIA Boro"
    },
    {
      key: "0230",
      label: "LITTLE FERRY Boro"
    },
    {
      key: "0231",
      label: "LODI Boro"
    },
    {
      key: "0232",
      label: "LYNDHURST Twp"
    },
    {
      key: "0233",
      label: "MAHWAH Twp"
    },
    {
      key: "0234",
      label: "MAYWOOD Boro"
    },
    {
      key: "0235",
      label: "MIDLAND PARK Boro"
    },
    {
      key: "0236",
      label: "MONTVALE Boro"
    },
    {
      key: "0237",
      label: "MOONACHIE Boro"
    },
    {
      key: "0238",
      label: "NEW MILFORD Boro"
    },
    {
      key: "0239",
      label: "NORTH ARLINGTON Boro"
    },
    {
      key: "0240",
      label: "NORTHVALE Boro"
    },
    {
      key: "0241",
      label: "NORWOOD Boro"
    },
    {
      key: "0242",
      label: "OAKLAND Boro"
    },
    {
      key: "0243",
      label: "OLD TAPPAN Boro"
    },
    {
      key: "0244",
      label: "ORADELL Boro"
    },
    {
      key: "0245",
      label: "PALISADES PARK Boro"
    },
    {
      key: "0246",
      label: "PARAMUS Boro"
    },
    {
      key: "0247",
      label: "PARK RIDGE Boro"
    },
    {
      key: "0248",
      label: "RAMSEY Boro"
    },
    {
      key: "0249",
      label: "RIDGEFIELD Boro"
    },
    {
      key: "0250",
      label: "RIDGEFIELD PARK VILLAGE"
    },
    {
      key: "0251",
      label: "RIDGEWOOD VILLAGE"
    },
    {
      key: "0252",
      label: "RIVEREDGE Boro"
    },
    {
      key: "0253",
      label: "RIVERVALE Twp"
    },
    {
      key: "0254",
      label: "ROCHELLE PARK Twp"
    },
    {
      key: "0255",
      label: "ROCKLEIGH Boro"
    },
    {
      key: "0256",
      label: "RUTHERFORD Boro"
    },
    {
      key: "0257",
      label: "SADDLE BROOK Twp"
    },
    {
      key: "0258",
      label: "SADDLE RIVER Boro"
    },
    {
      key: "0259",
      label: "SO HACKENSACK Twp"
    },
    {
      key: "0260",
      label: "TEANECK Twp"
    },
    {
      key: "0261",
      label: "TENAFLY Boro"
    },
    {
      key: "0262",
      label: "TETERBoro Boro"
    },
    {
      key: "0263",
      label: "UPPER SADDLE RIV Boro"
    },
    {
      key: "0264",
      label: "WALDWICK Boro"
    },
    {
      key: "0265",
      label: "WALLINGTON Boro"
    },
    {
      key: "0266",
      label: "WASHINGTON Twp"
    },
    {
      key: "0267",
      label: "WESTWOOD Boro"
    },
    {
      key: "0268",
      label: "WOODCLIFF LAKE Boro"
    },
    {
      key: "0269",
      label: "WOOD RIDGE Boro"
    },
    {
      key: "0270",
      label: "WYCKOFF Twp"
    }

      ]
    },
    {
      key: "03",
      label: "Burlington",
      active: true,
      municipalities: [
        {
          key: "0301",
          label: "Bass River Twp"
        },
        {
          key: "0302",
          label: "Beverly City"
        },
        {
          key: "0303",
          label: "Bordentown City"
        },
        {
          key: "0304",
          label: "Bordentown Twp."
        },
        {
          key: "0305",
          label: "Burlington City"
        },
        {
          key: "0306",
          label: "Burlington Twp"
        },
        {
          key: "0307",
          label: "Chesterfield Twp"
        },
        {
          key: "0308",
          label: "Cinnaminson Twp"
        },
        {
          key: "0309",
          label: "Delanco Twp"
        },
        {
          key: "0310",
          label: "Delran Twp"
        },
        {
          key: "0311",
          label: "Eastampton Twp"
        },
        {
          key: "0312",
          label: "Edgewater Park Twp"
        },
        {
          key: "0313",
          label: "Evesham Twp"
        },
        {
          key: "0314",
          label: "Fieldsboro Bor"
        },
        {
          key: "0315",
          label: "Florence Twp"
        },
        {
          key: "0316",
          label: "Hainesport Twp"
        },
        {
          key: "0317",
          label: "Lumberton Twp"
        },
        {
          key: "0318",
          label: "Mansfield Twp"
        },
        {
          key: "0319",
          label: "Maple Shade Twp"
        },
        {
          key: "0320",
          label: "Medford Twp"
        },
        {
          key: "0321",
          label: "Medford Lakes Bor"
        },
        {
          key: "0322",
          label: "Moorestown Twp"
        },
        {
          key: "0323",
          label: "Mount Holly Twp"
        },
        {
          key: "0324",
          label: "Mount Laurel Twp"
        },
        {
          key: "0325",
          label: "New Hanover Twp"
        },
        {
          key: "0326",
          label: "North Hanover Twp"
        },
        {
          key: "0327",
          label: "Palmyra Bor"
        },
        {
          key: "0328",
          label: "Pemberton Bor"
        },
        {
          key: "0329",
          label: "Pemberton Twp"
        },
        {
          key: "0330",
          label: "Riverside Twp"
        },
        {
          key: "0331",
          label: "Riverton Bor"
        },
        {
          key: "0332",
          label: "Shamong Twp"
        },
        {
          key: "0333",
          label: "Southampton Twp"
        },
        {
          key: "0334",
          label: "Springfield Twp"
        },
        {
          key: "0335",
          label: "Tabernacle Twp"
        },
        {
          key: "0336",
          label: "Washington Twp"
        },
        {
          key: "0337",
          label: "Westampton Twp"
        },
        {
          key: "0338",
          label: "Willingboro Twp"
        },
        {
          key: "0339",
          label: "Woodland Twp"
        },
        {
          key: "0340",
          label: "Wrightstown Bor"
        }
      ]
    },
    {
      key: "04",
      label: "Camden",
      active: true,
      municipalities: [
    {
      key: "0401",
      label: "Audubon Boro"
    },
    {
      key: "0402",
      label: "Audubon Park Boro"
    },
    {
      key: "0403",
      label: "Barrington Boro"
    },
    {
      key: "0404",
      label: "Bellmawr Boro"
    },
    {
      key: "0405",
      label: "Berlin Boro"
    },
    {
      key: "0406",
      label: "Berlin Twp"
    },
    {
      key: "0407",
      label: "Brooklawn Boro"
    },
    {
      key: "0408",
      label: "Camden City"
    },
    {
      key: "0409",
      label: "Cherry Hill Twnshp"
    },
    {
      key: "0410",
      label: "Chesilhurst Boro"
    },
    {
      key: "0411",
      label: "Clementon Boro"
    },
    {
      key: "0412",
      label: "Collingswood Boro"
    },
    {
      key: "0413",
      label: "Gibbsboro Boro"
    },
    {
      key: "0414",
      label: "Gloucester City"
    },
    {
      key: "0415",
      label: "Gloucester Twp"
    },
    {
      key: "0416",
      label: "Haddon Twp"
    },
    {
      key: "0417",
      label: "Haddonfield Boro"
    },
    {
      key: "0418",
      label: "Haddon Heights Boro"
    },
    {
      key: "0419",
      label: "Hi Nella Boro"
    },
    {
      key: "0420",
      label: "Laurel Springs Boro"
    },
    {
      key: "0421",
      label: "Lawnside Boro"
    },
    {
      key: "0422",
      label: "Lindenwold Boro"
    },
    {
      key: "0423",
      label: "Magnolia Boro"
    },
    {
      key: "0424",
      label: "Merchantville Boro"
    },
    {
      key: "0425",
      label: "Mount Ephraim Boro"
    },
    {
      key: "0426",
      label: "Oaklyn Boro"
    },
    {
      key: "0427",
      label: "Pennsauken Twp"
    },
    {
      key: "0428",
      label: "Pine Hill Boro"
    },
    {
      key: "0430",
      label: "Runnemede Boro"
    },
    {
      key: "0431",
      label: "Somerdale Boro"
    },
    {
      key: "0432",
      label: "Stratford Boro"
    },
    {
      key: "0433",
      label: "Tavistock Boro"
    },
    {
      key: "0434",
      label: "Voorhees Twp"
    },
    {
      key: "0435",
      label: "Waterford Twp"
    },
    {
      key: "0436",
      label: "Winslow Twp"
    },
    {
      key: "0437",
      label: "Woodlynne Boro"
    }

      ]
    },
    {
      key: "05",
      label: "Cape May",
      active: false,
      municipalities: [
    {
      key: "0501",
      label: "AVALON Boro"
    },
    {
      key: "0502",
      label: "CAPE MAY City"
    },
    {
      key: "0503",
      label: "CAPE MAY POINT Boro"
    },
    {
      key: "0504",
      label: "DENNIS Twp"
    },
    {
      key: "0505",
      label: "LOWER Twp"
    },
    {
      key: "0506",
      label: "MIDDLE Twp"
    },
    {
      key: "0507",
      label: "NORTH WILDWOOD City"
    },
    {
      key: "0508",
      label: "OCEAN City City"
    },
    {
      key: "0509",
      label: "SEA ISLE City City"
    },
    {
      key: "0510",
      label: "STONE HARBOR Boro"
    },
    {
      key: "0511",
      label: "UPPER Twp"
    },
    {
      key: "0512",
      label: "WEST Boro"
    },
    {
      key: "0513",
      label: "WEST WILDWOOD Boro"
    },
    {
      key: "0514",
      label: "WILDWOOD City"
    },
    {
      key: "0515",
      label: "WILDWOOD CREST Boro"
    },
    {
      key: "0516",
      label: "WOODBINE Boro"
    }

      ]
    },
    {
      key: "06",
      label: "Cumberland",
      active: false,
      municipalities: [
    {
      key: "0601",
      label: "BRIDGETON City"
    },
    {
      key: "0602",
      label: "COMMERCIAL Twp"
    },
    {
      key: "0603",
      label: "DEERFIELD Twp"
    },
    {
      key: "0604",
      label: "DOWNE Twp"
    },
    {
      key: "0605",
      label: "FAIRFIELD Twp"
    },
    {
      key: "0606",
      label: "GREENWICH Twp"
    },
    {
      key: "0607",
      label: "HOPEWELL Twp"
    },
    {
      key: "0608",
      label: "LAWRENCE Twp"
    },
    {
      key: "0609",
      label: "MAURICE RIVER Twp"
    },
    {
      key: "0610",
      label: "MILLVILLE City"
    },
    {
      key: "0611",
      label: "SHILOH Boro"
    },
    {
      key: "0612",
      label: "STOW CREEK Twp"
    },
    {
      key: "0613",
      label: "UPPER DEERFIELD Twp"
    },
    {
      key: "0614",
      label: "VINELAND City"
    }

      ]
    },
    {
      key: "07",
      label: "Essex",
      active: false,
      municipalities: [
    {
      key: "0701",
      label: "BELLEVILLE Twp"
    },
    {
      key: "0702",
      label: "BLOOMFIELD Twp"
    },
    {
      key: "0703",
      label: "CALDWELL Boro Twp"
    },
    {
      key: "0704",
      label: "CEDAR GROVE Twp"
    },
    {
      key: "0705",
      label: "EAST ORANGE City"
    },
    {
      key: "0706",
      label: "ESSEX FELLS Twp"
    },
    {
      key: "0707",
      label: "FAIRFIELD Twp"
    },
    {
      key: "0708",
      label: "GLEN RIDGE Boro"
    },
    {
      key: "0709",
      label: "IRVINGTON Twp"
    },
    {
      key: "0710",
      label: "LIVINGSTON Twp"
    },
    {
      key: "0711",
      label: "MAPLEWOOD Twp"
    },
    {
      key: "0712",
      label: "MILLBURN Twp"
    },
    {
      key: "0713",
      label: "MONTCLAIR Twp"
    },
    {
      key: "0714",
      label: "NEWARK City"
    },
    {
      key: "0715",
      label: "NORTH CALDWELL Twp"
    },
    {
      key: "0716",
      label: "NUTLEY Twp"
    },
    {
      key: "0717",
      label: "ORANGE City Twp"
    },
    {
      key: "0718",
      label: "ROSELAND Boro"
    },
    {
      key: "0719",
      label: "SOUTH ORANGE VILLAGE TW"
    },
    {
      key: "0720",
      label: "VERONA Twp"
    },
    {
      key: "0721",
      label: "WEST CALDWELL Twp"
    },
    {
      key: "0722",
      label: "WEST ORANGE Twp"
    }

      ]
    },
    {
      key: "08",
      label: "Gloucester",
      active: false,
      municipalities: [
    {
      key: "0801",
      label: "CLAYTON Boro"
    },
    {
      key: "0802",
      label: "DEPTFORD Twp"
    },
    {
      key: "0803",
      label: "EAST GREENWICH Twp"
    },
    {
      key: "0804",
      label: "ELK Twp"
    },
    {
      key: "0805",
      label: "FRANKLIN Twp"
    },
    {
      key: "0806",
      label: "GLASSBoro Boro"
    },
    {
      key: "0807",
      label: "GREENWICH Twp"
    },
    {
      key: "0808",
      label: "HARRISON Twp"
    },
    {
      key: "0809",
      label: "LOGAN Twp"
    },
    {
      key: "0810",
      label: "MANTUA Twp"
    },
    {
      key: "0811",
      label: "MONROE Twp"
    },
    {
      key: "0812",
      label: "NATIONAL PARK Boro"
    },
    {
      key: "0813",
      label: "NEWFIELD Boro"
    },
    {
      key: "0814",
      label: "PAULSBoro Boro"
    },
    {
      key: "0815",
      label: "PITMAN Boro"
    },
    {
      key: "0816",
      label: "SO HARRISON Twp"
    },
    {
      key: "0817",
      label: "SWEDESBoro Boro"
    },
    {
      key: "0818",
      label: "WASHINGTON Twp"
    },
    {
      key: "0819",
      label: "WENONAH Boro"
    },
    {
      key: "0820",
      label: "WEST DEPTFORD Twp"
    },
    {
      key: "0821",
      label: "WESTVILLE Boro"
    },
    {
      key: "0822",
      label: "WOODBURY City"
    },
    {
      key: "0823",
      label: "WOODBURY HEIGHTS Boro"
    },
    {
      key: "0824",
      label: "WOOLWICH Twp"
    }

      ]
    },
    {
      key: "09",
      label: "Hudson",
      active: false,
      municipalities: [
    {
      key: "0901",
      label: "BAYONNE City"
    },
    {
      key: "0902",
      label: "EAST NEWARK Boro"
    },
    {
      key: "0903",
      label: "GUTTENBERG Town"
    },
    {
      key: "0904",
      label: "HARRISON Town"
    },
    {
      key: "0905",
      label: "HOBOKEN City"
    },
    {
      key: "0906",
      label: "JERSEY City City"
    },
    {
      key: "0907",
      label: "KEARNY Town"
    },
    {
      key: "0908",
      label: "NORTH BERGEN Twp"
    },
    {
      key: "0909",
      label: "SECAUCUS Town"
    },
    {
      key: "0910",
      label: "UNION City City"
    },
    {
      key: "0911",
      label: "WEEHAWKEN Twp"
    },
    {
      key: "0912",
      label: "WEST NEW YORK Town"
    }

      ]
    },
    {
      key: "10",
      label: "Hunterdon",
      active: false,
      municipalities: [
    {
      key: "1001",
      label: "ALEXANDRIA Twp"
    },
    {
      key: "1002",
      label: "BETHLEHEM Twp"
    },
    {
      key: "1003",
      label: "BLOOMSBURY Boro"
    },
    {
      key: "1004",
      label: "CALIFON Boro"
    },
    {
      key: "1005",
      label: "CLINTON Town"
    },
    {
      key: "1006",
      label: "CLINTON Twp"
    },
    {
      key: "1007",
      label: "DELAWARE Twp"
    },
    {
      key: "1008",
      label: "EAST AMWELL Twp"
    },
    {
      key: "1009",
      label: "FLEMINGTON Boro"
    },
    {
      key: "1010",
      label: "FRANKLIN Twp"
    },
    {
      key: "1011",
      label: "FRENCHTown Boro"
    },
    {
      key: "1012",
      label: "GLEN GARDNER Boro"
    },
    {
      key: "1013",
      label: "HAMPTON Boro"
    },
    {
      key: "1014",
      label: "HIGH BRIDGE Boro"
    },
    {
      key: "1015",
      label: "HOLLAND Twp"
    },
    {
      key: "1016",
      label: "KINGWOOD Twp"
    },
    {
      key: "1017",
      label: "LAMBERTVILLE City"
    },
    {
      key: "1018",
      label: "LEBANON Boro"
    },
    {
      key: "1019",
      label: "LEBANON Twp"
    },
    {
      key: "1020",
      label: "MILFORD Boro"
    },
    {
      key: "1021",
      label: "RARITAN Twp"
    },
    {
      key: "1022",
      label: "READINGTON Twp"
    },
    {
      key: "1023",
      label: "STOCKTON Boro"
    },
    {
      key: "1024",
      label: "TEWKSBURY Twp"
    },
    {
      key: "1025",
      label: "UNION Twp"
    },
    {
      key: "1026",
      label: "WEST AMWELL Twp"
    }

      ]
    },
    {
      key: "11",
      label: "Mercer",
      active: false,
      municipalities: [
    {
      key: "1101",
      label: "EAST WINDSOR Twp"
    },
    {
      key: "1102",
      label: "EWING Twp"
    },
    {
      key: "1103",
      label: "HAMILTON Twp"
    },
    {
      key: "1104",
      label: "HIGHTSTown Boro"
    },
    {
      key: "1105",
      label: "HOPEWELL Boro"
    },
    {
      key: "1106",
      label: "HOPEWELL Twp"
    },
    {
      key: "1107",
      label: "LAWRENCE Twp"
    },
    {
      key: "1108",
      label: "PENNINGTON Boro"
    },
    {
      key: "1111",
      label: "TRENTON City"
    },
    {
      key: "1112",
      label: "ROBBINSVILLE Twp"
    },
    {
      key: "1113",
      label: "WEST WINDSOR Twp"
    },
    {
      key: "1114",
      label: "PRINCETON"
    }

      ]
    },
    {
      key: "12",
      label: "Middlesex",
      active: false,
      municipalities: [
    {
      key: "1201",
      label: "CARTERET Boro"
    },
    {
      key: "1202",
      label: "CRANBURY Twp"
    },
    {
      key: "1203",
      label: "DUNELLEN Boro"
    },
    {
      key: "1204",
      label: "EAST BRUNSWICK Twp"
    },
    {
      key: "1205",
      label: "EDISON Twp"
    },
    {
      key: "1206",
      label: "HELMETTA Boro"
    },
    {
      key: "1207",
      label: "HIGHLAND PARK Boro"
    },
    {
      key: "1208",
      label: "JAMESBURG Boro"
    },
    {
      key: "1209",
      label: "METUCHEN Boro"
    },
    {
      key: "1210",
      label: "MIDDLESEX Boro"
    },
    {
      key: "1211",
      label: "MILLTown Boro"
    },
    {
      key: "1212",
      label: "MONROE Twp"
    },
    {
      key: "1213",
      label: "NEW BRUNSWICK City"
    },
    {
      key: "1214",
      label: "NORTH BRUNSWICK Twp"
    },
    {
      key: "1215",
      label: "OLD BRIDGE Twp"
    },
    {
      key: "1216",
      label: "PERTH AMBOY City"
    },
    {
      key: "1217",
      label: "PISCATAWAY Twp"
    },
    {
      key: "1218",
      label: "PLAINSBoro Twp"
    },
    {
      key: "1219",
      label: "SAYREVILLE Boro"
    },
    {
      key: "1220",
      label: "SOUTH AMBOY City"
    },
    {
      key: "1221",
      label: "SOUTH BRUNSWICK Twp"
    },
    {
      key: "1222",
      label: "SOUTH PLAINFIELD Boro"
    },
    {
      key: "1223",
      label: "SOUTH RIVER Boro"
    },
    {
      key: "1224",
      label: "SPOTSWOOD Boro"
    },
    {
      key: "1225",
      label: "WOODBRIDGE Twp"
    }

      ]
    },
    {
      key: "13",
      label: "Monmouth",
      active: false,
      municipalities: [
    {
      key: "1301",
      label: "ABERDEEN Twp"
    },
    {
      key: "1302",
      label: "ALLENHURST Boro"
    },
    {
      key: "1303",
      label: "ALLENTown Boro"
    },
    {
      key: "1304",
      label: "ASBURY PARK City"
    },
    {
      key: "1305",
      label: "ATLANTIC HIGHLANDS Boro"
    },
    {
      key: "1306",
      label: "AVON BY THE SEA Boro"
    },
    {
      key: "1307",
      label: "BELMAR Boro"
    },
    {
      key: "1308",
      label: "BRADLEY BEACH Boro"
    },
    {
      key: "1309",
      label: "BRIELLE Boro"
    },
    {
      key: "1310",
      label: "COLTS NECK TownSHIP"
    },
    {
      key: "1311",
      label: "DEAL Boro"
    },
    {
      key: "1312",
      label: "EATONTown Boro"
    },
    {
      key: "1313",
      label: "ENGLISHTown Boro"
    },
    {
      key: "1314",
      label: "FAIR HAVEN Boro"
    },
    {
      key: "1315",
      label: "FARMINGDALE Boro"
    },
    {
      key: "1316",
      label: "FREEHOLD Boro"
    },
    {
      key: "1317",
      label: "FREEHOLD Twp"
    },
    {
      key: "1318",
      label: "HAZLET Twp"
    },
    {
      key: "1319",
      label: "HIGHLANDS Boro"
    },
    {
      key: "1320",
      label: "HOLMDEL Twp"
    },
    {
      key: "1321",
      label: "HOWELL Twp"
    },
    {
      key: "1322",
      label: "INTERLAKEN Boro"
    },
    {
      key: "1323",
      label: "KEANSBURG Boro"
    },
    {
      key: "1324",
      label: "KEYPORT Boro"
    },
    {
      key: "1325",
      label: "LITTLE SILVER Boro"
    },
    {
      key: "1326",
      label: "LOCH ARBOUR VILLAGE"
    },
    {
      key: "1327",
      label: "LONG BRANCH City"
    },
    {
      key: "1328",
      label: "MANALAPAN Twp"
    },
    {
      key: "1329",
      label: "MANASQUAN Boro"
    },
    {
      key: "1330",
      label: "MARLBoro Twp"
    },
    {
      key: "1331",
      label: "MATAWAN Boro"
    },
    {
      key: "1332",
      label: "MIDDLETown Twp"
    },
    {
      key: "1333",
      label: "MILLSTONE Twp"
    },
    {
      key: "1334",
      label: "MONMOUTH BEACH Boro"
    },
    {
      key: "1335",
      label: "NEPTUNE Twp"
    },
    {
      key: "1336",
      label: "NEPTUNE City Boro"
    },
    {
      key: "1337",
      label: "OCEAN Twp"
    },
    {
      key: "1338",
      label: "OCEANPORT Boro"
    },
    {
      key: "1339",
      label: "RED BANK Boro"
    },
    {
      key: "1340",
      label: "ROOSEVELT Boro"
    },
    {
      key: "1341",
      label: "RUMSON Boro"
    },
    {
      key: "1342",
      label: "SEA BRIGHT Boro"
    },
    {
      key: "1343",
      label: "SEA GIRT Boro"
    },
    {
      key: "1344",
      label: "SHREWSBURY Boro"
    },
    {
      key: "1345",
      label: "SHREWSBURY Twp"
    },
    {
      key: "1346",
      label: "LAKE COMO Boro"
    },
    {
      key: "1347",
      label: "SPRING LAKE Boro"
    },
    {
      key: "1348",
      label: "SPRING LAKE HEIGHTS BOR"
    },
    {
      key: "1349",
      label: "TINTON FALLS Boro"
    },
    {
      key: "1350",
      label: "UNION BEACH Boro"
    },
    {
      key: "1351",
      label: "UPPER FREEHOLD Twp"
    },
    {
      key: "1352",
      label: "WALL Twp"
    },
    {
      key: "1353",
      label: "WEST LONG BRANCH Boro"
    }

      ]
    },
    {
      key: "14",
      label: "Morris",
      active: false,
      municipalities: [
    {
      key: "1401",
      label: "BOONTON Town"
    },
    {
      key: "1402",
      label: "BOONTON Twp"
    },
    {
      key: "1403",
      label: "BUTLER Boro"
    },
    {
      key: "1404",
      label: "CHATHAM Boro"
    },
    {
      key: "1405",
      label: "CHATHAM Twp"
    },
    {
      key: "1406",
      label: "CHESTER Boro"
    },
    {
      key: "1407",
      label: "CHESTER Twp"
    },
    {
      key: "1408",
      label: "DENVILLE Twp"
    },
    {
      key: "1409",
      label: "DOVER Town"
    },
    {
      key: "1410",
      label: "EAST HANOVER Twp"
    },
    {
      key: "1411",
      label: "FLORHAM PARK Boro"
    },
    {
      key: "1412",
      label: "HANOVER Twp"
    },
    {
      key: "1413",
      label: "HARDING Twp"
    },
    {
      key: "1414",
      label: "JEFFERSON Twp"
    },
    {
      key: "1415",
      label: "KINNELON Boro"
    },
    {
      key: "1416",
      label: "LINCOLN PARK Boro"
    },
    {
      key: "1417",
      label: "MADISON Boro"
    },
    {
      key: "1418",
      label: "MENDHAM Boro"
    },
    {
      key: "1419",
      label: "MENDHAM Twp"
    },
    {
      key: "1420",
      label: "MINE HILL Twp"
    },
    {
      key: "1421",
      label: "MONTVILLE Twp"
    },
    {
      key: "1422",
      label: "MORRIS Twp"
    },
    {
      key: "1423",
      label: "MORRIS PLAINS Boro"
    },
    {
      key: "1424",
      label: "MORRISTown Town"
    },
    {
      key: "1425",
      label: "MOUNTAIN LAKES Boro"
    },
    {
      key: "1426",
      label: "MOUNT ARLINGTON Boro"
    },
    {
      key: "1427",
      label: "MOUNT OLIVE Twp"
    },
    {
      key: "1428",
      label: "NETCONG Boro"
    },
    {
      key: "1429",
      label: "PARSIPPANY TR HLS Twp"
    },
    {
      key: "1430",
      label: "LONG HILL Twp"
    },
    {
      key: "1431",
      label: "PEQUANNOCK Twp"
    },
    {
      key: "1432",
      label: "RANDOLPH Twp"
    },
    {
      key: "1433",
      label: "RIVERDALE Boro"
    },
    {
      key: "1434",
      label: "ROCKAWAY Boro"
    },
    {
      key: "1435",
      label: "ROCKAWAY Twp"
    },
    {
      key: "1436",
      label: "ROXBURY Twp"
    },
    {
      key: "1437",
      label: "VICTORY GARDENS Boro"
    },
    {
      key: "1438",
      label: "WASHINGTON Twp"
    },
    {
      key: "1439",
      label: "WHARTON Boro"
    }

      ]
    },
    {
      key: "15",
      label: "Ocean",
      active: false,
      municipalities: [
    {
      key: "1501",
      label: "BARNEGAT Twp"
    },
    {
      key: "1502",
      label: "BARNEGAT LIGHT Boro"
    },
    {
      key: "1503",
      label: "BAY HEAD Boro"
    },
    {
      key: "1504",
      label: "BEACH HAVEN Boro"
    },
    {
      key: "1505",
      label: "BEACHWOOD Boro"
    },
    {
      key: "1506",
      label: "BERKELEY Twp"
    },
    {
      key: "1507",
      label: "BRICK Twp"
    },
    {
      key: "1508",
      label: "TOMS RIVER Twp"
    },
    {
      key: "1509",
      label: "EAGLESWOOD Twp"
    },
    {
      key: "1510",
      label: "HARVEY CEDARS Boro"
    },
    {
      key: "1511",
      label: "ISLAND HEIGHTS Boro"
    },
    {
      key: "1512",
      label: "JACKSON Twp"
    },
    {
      key: "1513",
      label: "LACEY Twp"
    },
    {
      key: "1514",
      label: "LAKEHURST Boro"
    },
    {
      key: "1515",
      label: "LAKEWOOD Twp"
    },
    {
      key: "1516",
      label: "LAVALLETTE Boro"
    },
    {
      key: "1517",
      label: "LITTLE EGG HARBOR Twp"
    },
    {
      key: "1518",
      label: "LONG BEACH Twp"
    },
    {
      key: "1519",
      label: "MANCHESTER Twp"
    },
    {
      key: "1520",
      label: "MANTOLOKING Boro"
    },
    {
      key: "1521",
      label: "OCEAN Twp"
    },
    {
      key: "1522",
      label: "OCEAN GATE Boro"
    },
    {
      key: "1523",
      label: "PINE BEACH Boro"
    },
    {
      key: "1524",
      label: "PLUMSTED Twp"
    },
    {
      key: "1525",
      label: "POINT PLEASANT Boro"
    },
    {
      key: "1526",
      label: "PT PLEASANT BEACH Boro"
    },
    {
      key: "1527",
      label: "SEASIDE HEIGHTS Boro"
    },
    {
      key: "1528",
      label: "SEASIDE PARK Boro"
    },
    {
      key: "1529",
      label: "SHIP BOTTOM Boro"
    },
    {
      key: "1530",
      label: "SOUTH TOMS RIVER Boro"
    },
    {
      key: "1531",
      label: "STAFFORD Twp"
    },
    {
      key: "1532",
      label: "SURF City Boro"
    },
    {
      key: "1533",
      label: "TUCKERTON Boro"
    }

      ]
    },
    {
      key: "16",
      label: "Passaic",
      active: false,
      municipalities: [
    {
      key: "1601",
      label: "BLOOMINGDALE Boro"
    },
    {
      key: "1602",
      label: "CLIFTON City"
    },
    {
      key: "1603",
      label: "HALEDON Boro"
    },
    {
      key: "1604",
      label: "HAWTHORNE Boro"
    },
    {
      key: "1605",
      label: "LITTLE FALLS Twp"
    },
    {
      key: "1606",
      label: "NORTH HALEDON Boro"
    },
    {
      key: "1607",
      label: "PASSAIC City"
    },
    {
      key: "1608",
      label: "PATERSON City"
    },
    {
      key: "1609",
      label: "POMPTON LAKES Boro"
    },
    {
      key: "1610",
      label: "PROSPECT PARK Boro"
    },
    {
      key: "1611",
      label: "RINGWOOD Boro"
    },
    {
      key: "1612",
      label: "TOTOWA Boro"
    },
    {
      key: "1613",
      label: "WANAQUE Boro"
    },
    {
      key: "1614",
      label: "WAYNE Twp"
    },
    {
      key: "1615",
      label: "WEST MILFORD Twp"
    },
    {
      key: "1616",
      label: "WOODLAND PARK Boro"
    }

      ]
    },
    {
      key: "17",
      label: "Salem",
      active: false,
      municipalities: [
    {
      key: "1701",
      label: "ALLOWAY TownSHIP"
    },
    {
      key: "1702",
      label: "CARNEYS POINT TownSHIP"
    },
    {
      key: "1703",
      label: "ELMER Boro"
    },
    {
      key: "1704",
      label: "ELSINBoro Twp"
    },
    {
      key: "1705",
      label: "LOWER ALLOWAY CREEK Twp"
    },
    {
      key: "1706",
      label: "MANNINGTON Twp"
    },
    {
      key: "1707",
      label: "OLDMANS Twp"
    },
    {
      key: "1708",
      label: "PENNS GROVE Boro"
    },
    {
      key: "1709",
      label: "PENNSVILLE Twp"
    },
    {
      key: "1710",
      label: "PILESGROVE Twp"
    },
    {
      key: "1711",
      label: "PITTSGROVE Twp"
    },
    {
      key: "1712",
      label: "QUINTON Twp"
    },
    {
      key: "1713",
      label: "SALEM City"
    },
    {
      key: "1714",
      label: "UPPER PITTSGROVE Twp"
    },
    {
      key: "1715",
      label: "WOODSTown Boro"
    }

      ]
    },
    {
      key: "18",
      label: "Somerset",
      active: false,
      municipalities: [
    {
      key: "1801",
      label: "BEDMINSTER Twp"
    },
    {
      key: "1802",
      label: "BERNARDS Twp"
    },
    {
      key: "1803",
      label: "BERNARDSVILLE Boro"
    },
    {
      key: "1804",
      label: "BOUND BROOK Boro"
    },
    {
      key: "1805",
      label: "BRANCHBURG Twp"
    },
    {
      key: "1806",
      label: "BRIDGEWATER Twp"
    },
    {
      key: "1807",
      label: "FAR HILLS Boro"
    },
    {
      key: "1808",
      label: "FRANKLIN Twp"
    },
    {
      key: "1809",
      label: "GREEN BROOK Twp"
    },
    {
      key: "1810",
      label: "HILLSBoroUGH Twp"
    },
    {
      key: "1811",
      label: "MANVILLE Boro"
    },
    {
      key: "1812",
      label: "MILLSTONE Boro"
    },
    {
      key: "1813",
      label: "MONTGOMERY Twp"
    },
    {
      key: "1814",
      label: "NORTH PLAINFIELD Boro"
    },
    {
      key: "1815",
      label: "PEAPACK GLADSTONE Boro"
    },
    {
      key: "1816",
      label: "RARITAN Boro"
    },
    {
      key: "1817",
      label: "ROCKY HILL Boro"
    },
    {
      key: "1818",
      label: "SOMERVILLE Boro"
    },
    {
      key: "1819",
      label: "SO BOUND BROOK Boro"
    },
    {
      key: "1820",
      label: "WARREN Twp"
    },
    {
      key: "1821",
      label: "WATCHUNG Boro"
    }

      ]
    },
    {
      key: "19",
      label: "Sussex",
      active: false,
      municipalities: [
    {
      key: "1901",
      label: "ANDOVER Boro"
    },
    {
      key: "1902",
      label: "ANDOVER Twp"
    },
    {
      key: "1903",
      label: "BRANCHVILLE Boro"
    },
    {
      key: "1904",
      label: "BYRAM Twp"
    },
    {
      key: "1905",
      label: "FRANKFORD Twp"
    },
    {
      key: "1906",
      label: "FRANKLIN Boro"
    },
    {
      key: "1907",
      label: "FREDON Twp"
    },
    {
      key: "1908",
      label: "GREEN Twp"
    },
    {
      key: "1909",
      label: "HAMBURG Boro"
    },
    {
      key: "1910",
      label: "HAMPTON Twp"
    },
    {
      key: "1911",
      label: "HARDYSTON Twp"
    },
    {
      key: "1912",
      label: "HOPATCONG Boro"
    },
    {
      key: "1913",
      label: "LAFAYETTE Twp"
    },
    {
      key: "1914",
      label: "MONTAGUE Twp"
    },
    {
      key: "1915",
      label: "NEWTON Town"
    },
    {
      key: "1916",
      label: "OGDENSBURG Boro"
    },
    {
      key: "1917",
      label: "SANDYSTON Twp"
    },
    {
      key: "1918",
      label: "SPARTA Twp"
    },
    {
      key: "1919",
      label: "STANHOPE Boro"
    },
    {
      key: "1920",
      label: "STILLWATER Twp"
    },
    {
      key: "1921",
      label: "SUSSEX Boro"
    },
    {
      key: "1922",
      label: "VERNON Twp"
    },
    {
      key: "1923",
      label: "WALPACK Twp"
    },
    {
      key: "1924",
      label: "WANTAGE Twp"
    }

      ]
    },
    {
      key: "20",
      label: "Union",
      active: false,
      municipalities: [
    {
      key: "2001",
      label: "Berkeley Heights Twp"
    },
    {
      key: "2002",
      label: "Clark Twp"
    },
    {
      key: "2003",
      label: "Cranford Twp"
    },
    {
      key: "2004",
      label: "Elizabeth City"
    },
    {
      key: "2005",
      label: "Fanwood Boro"
    },
    {
      key: "2006",
      label: "Garwood Boro"
    },
    {
      key: "2007",
      label: "Hillside Twp"
    },
    {
      key: "2008",
      label: "Kenilworth Boro"
    },
    {
      key: "2009",
      label: "Linden City"
    },
    {
      key: "2010",
      label: "Mountainside Boro"
    },
    {
      key: "2011",
      label: "New Providence Boro"
    },
    {
      key: "2012",
      label: "Plainfield City"
    },
    {
      key: "2013",
      label: "Rahway City"
    },
    {
      key: "2014",
      label: "Roselle Boro"
    },
    {
      key: "2015",
      label: "Roselle Park Boro"
    },
    {
      key: "2016",
      label: "Scotch Plains Twp"
    },
    {
      key: "2017",
      label: "Springfield Twp"
    },
    {
      key: "2018",
      label: "Summit City"
    },
    {
      key: "2019",
      label: "Union Twp"
    },
    {
      key: "2020",
      label: "Westfield Town"
    },
    {
      key: "2021",
      label: "Winfield Twp"
    }

      ]
    },
    {
      key: "21",
      label: "Warren",
      active: false,
      municipalities: [
    {
      key: "2101",
      label: "Allamuchy Twp"
    },
    {
      key: "2102",
      label: "Alpha Boro"
    },
    {
      key: "2103",
      label: "Belvidere Town"
    },
    {
      key: "2104",
      label: "Blairstown Twp"
    },
    {
      key: "2105",
      label: "Franklin Twp"
    },
    {
      key: "2106",
      label: "Frelinghuysen Twp"
    },
    {
      key: "2107",
      label: "Greenwich Twp"
    },
    {
      key: "2108",
      label: "Hackettstown Town"
    },
    {
      key: "2109",
      label: "Hardwick Twp"
    },
    {
      key: "2110",
      label: "Harmony Twp"
    },
    {
      key: "2111",
      label: "Hope Twp"
    },
    {
      key: "2112",
      label: "Independence Twp"
    },
    {
      key: "2113",
      label: "Knowlton Twp"
    },
    {
      key: "2114",
      label: "Liberty Twp"
    },
    {
      key: "2115",
      label: "Lopatcong Twp"
    },
    {
      key: "2116",
      label: "Mansfield Twp"
    },
    {
      key: "2117",
      label: "Oxford Twp"
    },
    {
      key: "2119",
      label: "Phillipsburg Town"
    },
    {
      key: "2120",
      label: "Pohatcong Twp"
    },
    {
      key: "2121",
      label: "Washington Boro"
    },
    {
      key: "2122",
      label: "Washington Twp"
    },
    {
      key: "2123",
      label: "White Twp"
    }
      ]
    }

  ];

