import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {
  selectUserIsAppellant,
  selectUserIsAssessor,
  selectUserIsAuthorizedAttorney,
  selectUserIsSolicitor, selectUserName, setNetworkError
} from "./app";
import {RestStatus} from "./appealDetail";
import pako from "pako";
import {AppealType, PaymentMethod, PAYMENT_METHOD_ONLINE_CARD} from "../pages/DefaultPages/common/constants";
import {selectAddOmitSeason, selectValueClassSeason} from "./admin";


export const initialState = {
  appeal: {
    county: undefined,
    municipality: undefined,
    filingDescription: ' '
    ,
    property: {
      currentAssessment: {},
    },
    valuation: {
      appealing: false,
      taxCourtPending: false,
    },
    classification: {
      appealing: false,
    },
    deduction: {
      veteranDeduction: false,
      disabledVeteranExemption: false,
      seniorCitizenOrDisabledPerson: false,
      abatement: false,
    },
    hearingDate: null,
    hearingLocation: null,
    entryMethod: 'ONLINE',
    entryPaymentMethod: PAYMENT_METHOD_ONLINE_CARD,
    contact: {},
    additionalContact: {
      notify: false,
    },
    thirdPartyContact: {
      notify: false,
    },
    authorizedAttorneyContact: {
      notify: false,
    },
    creditCardPayment: {},
    fees: {
      propertyValuationFee: 0,
      deductionExemptionFee: 0,
      propertyClassificationFee: 0,
      convenienceFee: 0,
      total: 0,
    },
    crossAppeal: {
      isCrossAppeal: false,
    },
    notes: null,
    geolocation: {},
    deleted: false,
    tenRecords: [],
    improvementRecord: {},
    tenAppeal: false,
    hearingType: null,
    appVersion: process.env.REACT_APP_VERSION
  },
  batchAppealList: [],
  existingAppeals: [],
  matchingAppealsForBLQ: [],
  appealsThatMayBeCrossed: [],
}


export const calculateFee = createAsyncThunk(
  "appeal/calculateFee",
  async (param,{getState, dispatch}) => {
    const state = getState();
    const county = selectCounty(state);
    const userIsAssessor = selectUserIsAssessor(state);
    const userIsSolicitor = selectUserIsSolicitor(state);
    const userIsAuthorizedAttorney = selectUserIsAuthorizedAttorney(state);
    const userIsAppellant = selectUserIsAppellant(state);
    const filingDescription = selectFilingDescription(state);
    if( userIsAssessor || userIsSolicitor || userIsAuthorizedAttorney || (filingDescription === "A")) {
      return { propertyValuationFee: 0, deductionExemptionFee: 0, propertyClassificationFee: 0, convenienceFee: 0, total: 0 };
    }
    const response = await axios
      .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}/v2/${county.key}/fee`, {
        params: {
          currentAssessmentTotal: selectCurrentAssessmentTotal(state),
          currentAdditionAmount: selectCurrentAdditionAmount(state),
          appealingClassification: selectAppealingClassification(state),
          tenAppeal: selectIsTenAppeal(state),
          appealingValuation: selectAppealingValuation(state),
          appealingAbatementDenial: selectDeductionAbatement(state),
        },
      });
    const fees = response.data.fees;
    if( !userIsAppellant ){
      fees.total = fees.total - fees.convenienceFee;
      fees.convenienceFee = 0;
    }
    return fees;
  }
);

export const reserveAppealCounters = createAsyncThunk(
  "appeal/reserveAppealCounters",
  async ({count, appealYear}, {getState, dispatch}) => {
    const state = getState();
    const county = selectCounty(state);
    const municipality = selectMunicipality(state);
    const isTenAppeal = selectIsTenAppeal(state);
    const updateResponse = await axios
      .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}/v2/${county.key}/municipalities/${municipality.key}/appealcount`, {
        count: count,
        isTen: isTenAppeal,
        year: appealYear
      },
        {
        });
    return {nextCounter: updateResponse.data.firstCount};
  }
);

export const getExistingAppealsForMunicipalities = createAsyncThunk(
  "appeal/getExistingAppeals",
  async ({county, appealYear, isTen, municipalityIds, filterForAuthAttorney}, {getState}) => {
    const state = getState();
    const seasonConfiguration = isTen ? selectAddOmitSeason(state) : selectValueClassSeason(state);
    const judgmentSince = seasonConfiguration?.judgmentSinceFilter;
    const type = isTen ? 'ten' : 'twenty';
    const path = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}/v2/${county.key}/appeals?appealYear=${appealYear}&townId=${municipalityIds}&type=${type}&judgmentSince=${judgmentSince}`;
    const response = await axios.get(path);
    let data = JSON.parse(Buffer(pako.inflate(response.data.data)).toString());
    if (filterForAuthAttorney) {
      data = data.filter(obj => obj.APPEAL.authorizedAttorneyContact?.firstName);
    }
    return data;
  }
);

export const saveAppeal = createAsyncThunk(
  "appeal/saveAppeal",
  async ({appealCounter, saveSlotIndex, appeal}, {getState, dispatch}) => {
    const state = getState();
    const response = await axios
      .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}/v2/${appeal.county.key}/appeals`, {
        appeal: appeal,
        count: appealCounter,
        appealYear: appeal.appealYear
      },
        {
        });
    return {appealNumber: response.data.appealNumber, saveSlotIndex};
  }
);

export const appealSlice = createSlice({
  name: 'newAppeal',
  initialState: initialState,
  reducers: {
    setFlow: (state, action) => {
      state.flow = action.payload;
    },

    clearFlow: (state, action) => {
      state.flow = undefined;
    },

    setCounty: (state, action) => {
      const {key, label} = action.payload;
      // const taxBoard = key ? countyTaxBoards[key] : {};
      // state.appeal.county = {key, label, ...taxBoard};
      state.appeal.county = {key, label};
    },

    setMunicipality: (state, action) => {
      state.appeal.municipality = action.payload;
    },

    unsetMunicipality: state => {
      state.appeal.municipality = undefined;
    },

    setProperty: (state, action) => {
      state.appeal.property = action.payload;
    },

    unsetProperty: state => {
      state.appeal.property = initialState.appeal.property;
    },

    unsetValuation: state => {
      state.appeal.valuation = initialState.appeal.valuation;
    },

    setValuation: (state, action) => {
      state.appeal.valuation = action.payload;
    },

    unsetFees: state => {
      state.appeal.fees = initialState.appeal.fees;
      state.loadingFee = false;
      state.feeLoaded = false;
    },

    setBatchAppealList: (state, action) => {
      state.batchAppealList = action.payload;
    },

    unsetBatchAppealList: state => {
      state.batchAppealList = [];
    },

    setIsCrossAppeal: (state, action) => {
      state.appeal.crossAppeal.isCrossAppeal = action.payload;
    },

    setOtherAppeal: (state, action) => {
      state.appeal.crossAppeal.otherAppeal = action.payload;
    },

    unsetCrossAppeal: (state, action) => {
      state.appeal.crossAppeal = {isCrossAppeal: action.payload};
    },

    setIsTenAppeal: (state, action) => {
      state.appeal.tenAppeal = action.payload;
    },

    setAppealYear: (state, action) => {
      state.appeal.appealYear = action.payload;
    },

    setHearingType: (state, action) => {
      state.appeal.hearingType = action.payload;
    },

    setAppealingValuation: (state, action) => {
      state.appeal.valuation.appealing = action.payload;
    },

    setCurrentLandValuation: (state, action) => {
      state.appeal.valuation.currentLand = action.payload;
    },

    setCurrentImprovementValuation: (state, action) => {
      state.appeal.valuation.currentImprovement = action.payload;
    },

    setCurrentAbatementValuation: (state, action) => {
      state.appeal.valuation.currentAbatement = action.payload;
    },

    setCurrentTotalValuation: (state, action) => {
      state.appeal.valuation.currentTotal = action.payload;
    },

    setPurchasePrice: (state, action) => {
      state.appeal.valuation.purchasePrice = action.payload;
      state.appeal.property.purchasePrice = action.payload;
    },

    setPurchaseDate: (state, action) => {
      state.appeal.valuation.purchaseDate = action.payload;
      state.appeal.property.purchaseDate = action.payload;
    },

    setRequestedTotalAssessment: (state, action) => {
      state.appeal.valuation.requestedTotal = action.payload;
    },

    unsetRequestedTotalAssessment: state => {
      state.appeal.valuation.requestedTotal = undefined;
    },

    setTaxCourtPending: (state, action) => {
      state.appeal.valuation.taxCourtPending = action.payload;
    },

    unsetTaxCourtPending: state => {
      state.appeal.valuation.taxCourtPending = undefined;
    },

    setReasonForAppeal: (state, action) => {
      state.appeal.valuation.reason = action.payload;
    },

    unsetReasonForAppeal: state => {
      state.appeal.valuation.reason = undefined;
    },

    setAppealingClass: (state, action) => {
      state.appeal.classification.appealing = action.payload;
    },

    setRequestedClass: (state, action) => {
      state.appeal.classification.requestedClass = action.payload;
    },

    setCurrentClass: (state, action) => {
      state.appeal.classification.currentClass = action.payload;
    },

    unsetRequestedClass: state => {
      state.appeal.classification.requestedClass = undefined;
    },

    setDeductionAction: (state, action) => {
      state.appeal.deduction.action = action.payload;
    },

    setVeteranDeduction: (state, action) => {
      state.appeal.deduction.veteranDeduction = action.payload;
    },

    setDisabledVeteranExemption: (state, action) => {
      state.appeal.deduction.disabledVeteranExemption = action.payload;
    },

    setSeniorCitizenOrDisabledPersonDeduction: (state, action) => {
      state.appeal.deduction.seniorCitizenOrDisabledPerson = action.payload;
    },

    setAbatementDeduction: (state, action) => {
      state.appeal.deduction.abatement = action.payload;
    },

    //Begin Contact
    setContactFirstName: (state, action) => {
      state.appeal.contact.firstName = action.payload;
    },
    setContactLastName: (state, action) => {
      state.appeal.contact.lastName = action.payload;
    },
    setContactAddress: (state, action) => {
      state.appeal.contact.address = action.payload;
    },
    setContactCity: (state, action) => {
      state.appeal.contact.city = action.payload;
    },
    setContactState: (state, action) => {
      state.appeal.contact.state = action.payload;
    },
    setContactZipCode: (state, action) => {
      state.appeal.contact.zipCode = action.payload;
    },
    setContactEmailAddress: (state, action) => {
      state.appeal.contact.emailAddress = action.payload;
    },
    setContactDayTimePhone: (state, action) => {
      state.appeal.contact.dayTimePhone = action.payload;
    },
    setContactCellPhone: (state, action) => {
      state.appeal.contact.cellPhone = action.payload;
    },
    setMainEmailCheckbox: (state, action) => {
      state.appeal.contact.mainEmailCheckbox = action.payload;
    },
    setMainPhoneCheckbox: (state, action) => {
      state.appeal.contact.mainPhoneCheckbox = action.payload;
    },

    //Begin Additional Contact
    setNotifyAdditionalContact: (state, action) => {
      state.appeal.additionalContact.notify = action.payload;
    },
    setAdditionalContactFirstName: (state, action) => {
      state.appeal.additionalContact.firstName = action.payload;
    },
    setAdditionalContactLastName: (state, action) => {
      state.appeal.additionalContact.lastName = action.payload;
    },
    setAdditionalContactAddress: (state, action) => {
      state.appeal.additionalContact.address = action.payload;
    },
    setAdditionalContactCity: (state, action) => {
      state.appeal.additionalContact.city = action.payload;
    },
    setAdditionalContactState: (state, action) => {
      state.appeal.additionalContact.state = action.payload;
    },
    setAdditionalContactZipCode: (state, action) => {
      state.appeal.additionalContact.zipCode = action.payload;
    },
    setAdditionalContactEmailAddress: (state, action) => {
      state.appeal.additionalContact.emailAddress = action.payload;
    },
    setAdditionalContactDayTimePhone: (state, action) => {
      state.appeal.additionalContact.dayTimePhone = action.payload;
    },
    setAdditionalContactCellPhone: (state, action) => {
      state.appeal.additionalContact.cellPhone = action.payload;
    },
    setACLawyerLicense: (state, action) => {
      state.appeal.additionalContact.aCLawyerLicense = action.payload;
    },
    setContactEmailCheckbox: (state, action) => {
      state.appeal.additionalContact.contactEmailCheckbox = action.payload;
    },
    setContactPhoneCheckbox: (state, action) => {
      state.appeal.additionalContact.contactPhoneCheckbox = action.payload;
    },
    setIsLawyer: (state, action) => {
      state.appeal.additionalContact.isLawyer = action.payload;
    },
    setIsAdditionalContact: (state, action) => {
      state.appeal.additionalContact.isAdditionalContact = action.payload;
    },
    unsetIsAdditionalContact: (state) => {
      state.appeal.additionalContact = {};
    },

    //Begin ThirdParty Contact
    setNotifyThirdPartyContact: (state, action) => {
      state.appeal.thirdPartyContact.notify = action.payload;
    },
    setThirdPartyContactFirstName: (state, action) => {
      state.appeal.thirdPartyContact.firstName = action.payload;
    },
    setThirdPartyContactLastName: (state, action) => {
      state.appeal.thirdPartyContact.lastName = action.payload;
    },
    setThirdPartyContactAddress: (state, action) => {
      state.appeal.thirdPartyContact.address = action.payload;
    },
    setThirdPartyContactCity: (state, action) => {
      state.appeal.thirdPartyContact.city = action.payload;
    },
    setThirdPartyContactState: (state, action) => {
      state.appeal.thirdPartyContact.state = action.payload;
    },
    setThirdPartyContactZipCode: (state, action) => {
      state.appeal.thirdPartyContact.zipCode = action.payload;
    },
    setThirdPartyContactEmailAddress: (state, action) => {
      state.appeal.thirdPartyContact.emailAddress = action.payload;
    },
    setThirdPartyContactDayTimePhone: (state, action) => {
      state.appeal.thirdPartyContact.dayTimePhone = action.payload;
    },
    setThirdPartyContactCellPhone: (state, action) => {
      state.appeal.thirdPartyContact.cellPhone = action.payload;
    },
    setIsThirdPartyContact: (state, action) => {
      state.appeal.thirdPartyContact.isThirdPartyContact = action.payload;
    },
    unsetIsThirdPartyContact: (state) => {
      state.appeal.thirdPartyContact = {};
    },
    setThirdPartyEmailCheckbox: (state, action) => {
      state.appeal.thirdPartyContact.thirdPartyEmailCheckbox = action.payload;
    },
    setThirdPartyPhoneCheckbox: (state, action) => {
      state.appeal.thirdPartyContact.thirdPartyPhoneCheckbox = action.payload;
    },
    setAuthorizedAttorneyContactFirstName: (state, action) => {
      state.appeal.authorizedAttorneyContact.firstName = action.payload;
    },
    setAuthorizedAttorneyContactLastName: (state, action) => {
      state.appeal.authorizedAttorneyContact.lastName = action.payload;
    },
    setAuthorizedAttorneyContactAddress: (state, action) => {
      state.appeal.authorizedAttorneyContact.address = action.payload;
    },
    setAuthorizedAttorneyContactCity: (state, action) => {
      state.appeal.authorizedAttorneyContact.city = action.payload;
    },
    setAuthorizedAttorneyContactState: (state, action) => {
      state.appeal.authorizedAttorneyContact.state = action.payload;
    },
    setAuthorizedAttorneyContactZipCode: (state, action) => {
      state.appeal.authorizedAttorneyContact.zipCode = action.payload;
    },
    setAuthorizedAttorneyContactEmailAddress: (state, action) => {
      state.appeal.authorizedAttorneyContact.emailAddress = action.payload;
    },
    setAuthorizedAttorneyContactDayTimePhone: (state, action) => {
      state.appeal.authorizedAttorneyContact.dayTimePhone = action.payload;
    },
    setAuthorizedAttorneyContactCellPhone: (state, action) => {
      state.appeal.authorizedAttorneyContact.cellPhone = action.payload;
    },
    setIsAuthorizedAttorneyContact: (state, action) => {
      state.appeal.authorizedAttorneyContact.isAuthorizedAttorneyContact = action.payload;
    },
    unsetIsAuthorizedAttorneyContact: (state) => {
      state.appeal.authorizedAttorneyContact = {};
    },
    setAuthorizedAttorneyEmailCheckbox: (state, action) => {
      state.appeal.authorizedAttorneyContact.authorizedAttorneyEmailCheckbox = action.payload;
    },
    setAuthorizedAttorneyPhoneCheckbox: (state, action) => {
      state.appeal.authorizedAttorneyContact.authorizedAttorneyPhoneCheckbox = action.payload;
    },
    setAALawyerLicense: (state, action) => {
      state.appeal.authorizedAttorneyContact.aALawyerLicense = action.payload;
    },

    // End

    setTenRecords: (state, action) => {
      state.appeal.tenRecords = action.payload;
    },

    setSelectedTen: (state, action) => {
      state.appeal.improvementRecord.originalRecord = action.payload;
      state.appeal.improvementRecord.listType = action.payload?.listType;
      state.appeal.improvementRecord.taxYear = action.payload?.taxYear;
    },
    setAdditionListType: (state, action) => {
      state.appeal.improvementRecord.listType = action.payload;
    },
    setAdditionTaxYear: (state, action) => {
      state.appeal.improvementRecord.taxYear = action.payload;
    },
    setAdditionPropertyClass: (state,action) => {
      state.appeal.improvementRecord.propertyClass = action.payload;
    },
    setRequestedAdditionDate: (state, action) => {
      state.appeal.improvementRecord.requestedAdditionDate = action.payload;
    },

    setRequestedAdditionMonth: (state, action) => {
      state.appeal.improvementRecord.requestedAdditionMonth = action.payload;
    },

    setRequestedAdditionAmount: (state, action) => {
      state.appeal.improvementRecord.requestedAdditionAmount = action.payload;
    },


    setRequestedAdditionLandAmount: (state, action) => {
      state.appeal.improvementRecord.requestedAdditionLand = action.payload;
    },


    setRequestedAdditionImprovementAmount: (state, action) => {
      state.appeal.improvementRecord.requestedAdditionImprovement = action.payload;
    },


    setRequestedAdditionAbatementAmount: (state, action) => {
      state.appeal.improvementRecord.requestedAdditionAbatement = action.payload;
    },

    setCreditCardNumber: (state, action) => {
      state.appeal.creditCardPayment.cardNumber = action.payload;
    },

    setCreditCardExpiration: (state, action) => {
      state.appeal.creditCardPayment.expiration = action.payload;
    },

    setCreditCardCvc: (state, action) => {
      state.appeal.creditCardPayment.cvc = action.payload;
    },

    setCreditCardHolderName: (state, action) => {
      state.appeal.creditCardPayment.cardHolderName = action.payload;
    },

    setEntryMethod: (state, action) => {
      state.appeal.entryMethod = action.payload;
    },
    setEntryPaymentMethod: (state, action) => {
      state.appeal.entryPaymentMethod = action.payload;
    },

    setFees: (state, action) => {
      state.appeal.fees = action.payload;
      state.loadingFee = false;
      state.feeLoaded = true;
    },

    setReceiptId: (state, action) => {
      state.appeal.receiptId = action.payload;
    },

    setScanFile: (state, action) => {
      state.appeal.scanFile = action.payload;
    },

    setScanDenial: (state, action) => {
      state.appeal.scanDenial = action.payload;
    },

    setFilingDescription: (state, action) => {
      state.appeal.filingDescription = action.payload;
    },
    setIsBatchTemplate: (state, action) => {
      state.appeal.isBatchTemplate = action.payload;
    },

    // saveComparableSales: (state, data) => {
    //   state.appeal.comparableSales = action.payload;
    // },

    // setComparableSaleBlq: (list, data) => {
    //   return [
    //     ...list.slice(0, data.index),
    //     {
    //       ...listdata.index,
    //       blq: data.blq,
    //     },
    //     ...list.slice(data.index + 1),
    //   ]
    // },

    // setComparableSaleAddress: (list, data) => {
    //   return [
    //     ...list.slice(0, data.index),
    //     {
    //       ...listdata.index,
    //       address: data.address,
    //     },
    //     ...list.slice(data.index + 1),
    //   ]
    // },

    // setComparableSalePrice: (list, data) => {
    //   return [
    //     ...list.slice(0, data.index),
    //     {
    //       ...listdata.index,
    //       salePrice: data.salePrice,
    //     },
    //     ...list.slice(data.index + 1),
    //   ]
    // },

    // setComparableSaleDate: (list, data) => {
    //   return [
    //     ...list.slice(0, data.index),
    //     {
    //       ...listdata.index,
    //       saleDate: data.saleDate,
    //     },
    //     ...list.slice(data.index + 1),
    //   ]
    // },

    // removeComparableSale: (list, index) => {
    //   return ...list.slice(0, index), ...list.slice(index + 1)
    // },

    // addComparableSale: (state, key) => ({
    //   ...state,
    //   comparableSales: [
    //     ...state.comparableSales,
    //     {
    //       key: key,
    //       blq: '',
    //       address: '',
    //       salePrice: '',
    //       saleDate: '',
    //     },
    //   ],
    // }),

    // updateComparableSale: (state, data) => {
    //   const { key, row } = data
    //   return {
    //     ...state,
    //     comparableSales: state.appeal.comparableSales.map(comp => {
    //       if (comp.key !== key) {
    //         return comp
    //       }

    //       return {
    //         key: row.key,
    //         blq: row.blq,
    //         address: row.address,
    //         salePrice: row.salePrice,
    //         saleDate: row.saleDate,
    //       }
    //     }),
    //   }
    // },

    refuseInspection: (state, action) => {
      state.appeal.refuseInspection = action.payload;
    },
    rememberExistingAppealsForBLQ: (state, action) => {
      state.matchingAppealsForBLQ = action.payload;
    },
    rememberAppealsThatMayBeCrossed: (state, action) => {
      state.appealsThatMayBeCrossed = action.payload;
    },
    clearAppeal: (state, action) => {
      state.appeal = initialState.appeal;
      state.matchingAppealsForBLQ = [];
      state.appealsThatMayBeCrossed = [];
      state.saveSlots = null;
    },
    prepareForSave: (state, action) => {
      const numberToSave = action.payload;
      state.saveSlots = Array(numberToSave);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(reserveAppealCounters.pending, (state, action) => {
        state.reservingCounters = true;
        state.reservingCountersError = '';
      })
      .addCase(reserveAppealCounters.fulfilled, (state, action) => {
        const {nextCounter} = action.payload;
        state.reservingCounters = false;
        state.countersReserved = true;
        state.nextCounter = nextCounter;
      })
      .addCase(reserveAppealCounters.rejected, (state, action) => {
        state.reservingCounters = false;
        state.reservingCountersError = action.error.message;
      })
      .addCase(saveAppeal.pending, (state, action) => {
        const {saveSlotIndex} = action.meta.arg;
        state.saveSlots[saveSlotIndex] = {status:RestStatus.SAVING};
      })
      .addCase(saveAppeal.fulfilled, (state, action) => {
        const {saveSlotIndex, appealNumber} = action.payload;
        state.saveSlots[saveSlotIndex] = {status: RestStatus.SAVED, appealNumber};
      })
      .addCase(saveAppeal.rejected, (state, action) => {
        const {saveSlotIndex} = action.meta.arg;
        state.saveSlots[saveSlotIndex] = {status: RestStatus.SAVED, appealNumber}
      })
      .addCase(calculateFee.pending, (state, action) => {
        state.loadingFee = true;
        state.feeLoaded = false;
        state.loadingFeeError = '';
      })
      .addCase(calculateFee.fulfilled, (state, action) => {
        state.loadingFee = false;
        state.feeLoaded = true;
        state.appeal.fees = action.payload;
        if (action.payload.total === 0) {
          state.appeal.entryPaymentMethod = PaymentMethod.NONE;
        }
      })
      .addCase(calculateFee.rejected, (state, action) => {
        state.loadingFee = false;
        state.loadingFeeError = action.error.message;
      })
      .addCase(getExistingAppealsForMunicipalities.pending, (state, action) => {
        state.existingAppealsLoadStatus = RestStatus.LOADING;
        state.existingAppeals = [];
      })
      .addCase(getExistingAppealsForMunicipalities.fulfilled, (state, action) => {
        state.existingAppealsLoadStatus = RestStatus.LOADED;
        state.existingAppeals = action.payload;
      })
      .addCase(getExistingAppealsForMunicipalities.rejected, (state, action) => {
        state.existingAppealsLoadStatus = RestStatus.LOAD_FAILED;
        state.existingAppealsError = action.error.message;
      })
  }
});
export const {setCounty, setMunicipality, unsetMunicipality, setProperty, unsetProperty, setBatchAppealList, unsetBatchAppealList, setIsCrossAppeal, setOtherAppeal, unsetCrossAppeal,
  setAppealingValuation, setCurrentLandValuation, setCurrentImprovementValuation, setCurrentAbatementValuation, setCurrentTotalValuation, setPurchasePrice, 
  setPurchaseDate, setRequestedTotalAssessment, unsetRequestedTotalAssessment, setTaxCourtPending, unsetTaxCourtPending, setReasonForAppeal,
  unsetReasonForAppeal, setAppealingClass, setCurrentClass, setRequestedClass, unsetRequestedClass, setVeteranDeduction, setDisabledVeteranExemption,
  setSeniorCitizenOrDisabledPersonDeduction, setAbatementDeduction, setContactFirstName, setContactLastName, setContactAddress, setContactCity,
  setContactState, setContactZipCode, setContactEmailAddress, setContactDayTimePhone, setContactCellPhone, setMainEmailCheckbox, setMainPhoneCheckbox,
  setNotifyAdditionalContact, setAdditionalContactFirstName, setAdditionalContactLastName, setAdditionalContactAddress, setAdditionalContactCity,
  setAdditionalContactState, setAdditionalContactZipCode, setAdditionalContactEmailAddress, setAdditionalContactDayTimePhone, setAdditionalContactCellPhone,
  setACLawyerLicense, setContactEmailCheckbox, setContactPhoneCheckbox, setIsLawyer, setIsAdditionalContact, unsetIsAdditionalContact,
  setNotifyThirdPartyContact, setThirdPartyContactFirstName, setThirdPartyContactLastName, setThirdPartyContactAddress, setThirdPartyContactCity,
  setThirdPartyContactState, setThirdPartyContactZipCode, setThirdPartyEmailCheckbox, setThirdPartyPhoneCheckbox, setThirdPartyContactEmailAddress,
  setThirdPartyContactDayTimePhone, setThirdPartyContactCellPhone, setIsThirdPartyContact, unsetIsThirdPartyContact, setAALawyerLicense,
  setNotifyAuthorizedAttorneyContact, setAuthorizedAttorneyContactFirstName, setAuthorizedAttorneyContactLastName, setAuthorizedAttorneyContactAddress,
  setAuthorizedAttorneyContactCity, setAuthorizedAttorneyContactState, setAuthorizedAttorneyContactZipCode, setAuthorizedAttorneyContactEmailAddress,
  setAuthorizedAttorneyContactDayTimePhone, setAuthorizedAttorneyContactCellPhone, setAuthorizedAttorneyEmailCheckbox, setAuthorizedAttorneyPhoneCheckbox,
  setIsAuthorizedAttorneyContact, unsetIsAuthorizedAttorneyContact, setCreditCardNumber, setCreditCardExpiration, setCreditCardCvc, setCreditCardHolderName,
  setEntryMethod, setEntryPaymentMethod, setReceiptId, setScanFile, setScanDenial, setFilingDescription, setFees, addComparableSale, setComparableSaleBlq,
  setComparableSaleAddress, setComparableSalePrice, setComparableSaleDate, removeComparableSale, updateComparableSale, deleteComparableSale,
  saveComparableSales, setIsTenAppeal, setTenRecords, setSelectedTen, setRequestedAdditionDate, setRequestedAdditionAmount, setRequestedAdditionLandAmount,
  setRequestedAdditionImprovementAmount, setRequestedAdditionAbatementAmount, setRequestedAdditionMonth, setHearingType, refuseInspection,
  clearAppeal, setAppealYear, setIsBatchTemplate, prepareForSave, setFlow, clearFlow, setValuation, unsetValuation, unsetFees,
  setAdditionListType, setAdditionTaxYear, rememberExistingAppealsForBLQ, rememberAppealsThatMayBeCrossed, setAdditionPropertyClass} = appealSlice.actions;

export const selectFlow = state => state.newAppeal.flow;
export const selectAppealYear = state => state.newAppeal.appeal.appealYear;
export const selectCounty = state => state.newAppeal.appeal.county;
export const selectMunicipality = state => state.newAppeal.appeal.municipality;
export const selectIsTenAppeal = state => state.newAppeal.appeal.tenAppeal;
export const selectTenRecords = state => state.newAppeal.appeal.tenRecords;
export const selectAppeal = state => state.newAppeal.appeal;
export const selectCurrentAssessmentLand = state => state.newAppeal.appeal.property.currentAssessment.land;
export const selectCurrentAssessmentBuildingImprovement = state => state.newAppeal.appeal.property.currentAssessment.buildingImprovement;
export const selectCurrentAssessmentAbatement = state => state.newAppeal.appeal.property.currentAssessment.abatement;
export const selectCurrentAssessmentTotal = state => state.newAppeal.appeal.property.currentAssessment.total;
export const selectCurrentAdditionAmount = state => {
  const originalRecord = state.newAppeal.appeal.improvementRecord.originalRecord;
  return originalRecord
    ? (originalRecord.additionImprovement + originalRecord.additionLand - originalRecord.additionAbatement) * (Number(originalRecord.totalMonthsAssessed) / 12)
    : 0;
};
export const selectAssessmentAddition = state => state.newAppeal.appeal.improvementRecord.originalRecord;
export const selectAdditionListType = state => state.newAppeal.appeal.improvementRecord.listType;
export const selectAdditionTaxYear = state => state.newAppeal.appeal.improvementRecord.taxYear;
export const selectAdditionPropertyClass = state => state.newAppeal.appeal.improvementRecord.propertyClass;
export const selectRequestedAdditionLand = state => state.newAppeal.appeal.improvementRecord.requestedAdditionLand;
export const selectRequestedAdditionImprovement = state => state.newAppeal.appeal.improvementRecord.requestedAdditionImprovement;
export const selectRequestedAdditionAbatement = state => state.newAppeal.appeal.improvementRecord.requestedAdditionAbatement;
export const selectRequestedAdditionAmount = state => state.newAppeal.appeal.improvementRecord.requestedAdditionAmount;
export const selectRequestedAdditionDate = state => state.newAppeal.appeal.improvementRecord.requestedAdditionDate;
export const selectRequestedAdditionMonth = state => state.newAppeal.appeal.improvementRecord.requestedAdditionMonth;
export const selectRequestedPropertyClassification = state => state.newAppeal.appeal.classification?.requestedClass;
export const selectAppealingClassification = state => state.newAppeal.appeal.classification.appealing;
export const selectAppealingValuation = state => state.newAppeal.appeal.valuation.appealing;
export const selectRequestedTotal = state => state.newAppeal.appeal.valuation.requestedTotal;
export const selectReasonForAppeal = state => state.newAppeal.appeal.valuation.reason;
export const selectTaxCourtPending = state => state.newAppeal.appeal.valuation.taxCourtPending;
export const selectPurchasePrice = state => state.newAppeal.appeal.valuation.purchasePrice;
export const selectPurchaseDate = state => state.newAppeal.appeal.valuation.purchaseDate;
export const selectProperty = state => state.newAppeal.appeal.property;
export const selectPropertyClassification = state => state.newAppeal.appeal.property.classification;
export const selectAdditionalContactIsLawyer = state => state.newAppeal.appeal.additionalContact?.isLawyer;
export const selectContactName = state => state.newAppeal.appeal.contact.firstName;
export const selectContactEmailAddress = state => state.newAppeal.appeal.contact.emailAddress;
export const selectContactDayTimePhone = state => state.newAppeal.appeal.contact.dayTimePhone;
export const selectAdditionalContactName = state => state.newAppeal.appeal.additionalContact.firstName;
export const selectAdditionalContactEmailAddress = state => state.newAppeal.appeal.additionalContact.emailAddress;
export const selectAdditionalContactDayTimePhone = state => state.newAppeal.appeal.additionalContact.dayTimePhone;
export const selectHasAdditionalContact = state => state.newAppeal.appeal.additionalContact.isAdditionalContact;
export const selectHasThirdPartyContact = state => state.newAppeal.appeal.thirdPartyContact?.isThirdPartyContact;
export const selectHasAuthorizedAttorneyContact = state => !!state.newAppeal.appeal.authorizedAttorneyContact;
export const selectContact = state => state.newAppeal.appeal.contact;
export const selectAdditionalContact = state => state.newAppeal.appeal.additionalContact;
export const selectThirdPartyContact = state => state.newAppeal.appeal.thirdPartyContact;
export const selectAuthorizedAttorneyContact = state => state.newAppeal.appeal.authorizedAttorneyContact;
export const selectSaveSlots = state => state.newAppeal.saveSlots;
export const selectFilingDescription = state => state.newAppeal.appeal.filingDescription;

export const selectBatchAppealList = state => state.newAppeal.batchAppealList;
export const selectExistingAppeals = state => state.newAppeal.existingAppeals;
export const selectAppealsThatMayBeCrossed = state => state.newAppeal.appealsThatMayBeCrossed;
export const selectExistingAppealsForBLQ = state => state.newAppeal.matchingAppealsForBLQ;
export const selectExistingAppealsLoadStatus = state => state.newAppeal.existingAppealsLoadStatus;
export const selectExistingAppealsError = state => state.newAppeal.existingAppealsError;
export const selectFees = state => state.newAppeal.appeal.fees;
export const selectFeeLoading = state => state.newAppeal.loadingFee;
export const selectFeeLoaded = state => state.newAppeal.feeLoaded;
export const selectDeductionVeteranDeduction = state => state.newAppeal.appeal.deduction.veteranDeduction;
export const selectDeductionDisabledVeteranExemption = state => state.newAppeal.appeal.deduction.disabledVeteranExemption;
export const selectDeductionSeniorCitizenOrDisabledPerson = state => state.newAppeal.appeal.deduction.seniorCitizenOrDisabledPerson;
export const selectDeductionAbatement = state => state.newAppeal.appeal.deduction.abatement;
export const selectEntryPaymentMethod = state => state.newAppeal.appeal.entryPaymentMethod;
export const selectReceiptId = state => state.newAppeal.appeal.receiptId;
export const selectEntryMethod = state => state.newAppeal.appeal.entryMethod;
export const selectHearingType = state => state.newAppeal.appeal.hearingType;
export const selectScanDenial = state => state.newAppeal.appeal.scanDenial;

export default appealSlice.reducer;
