export default [
  {
    title: 'File a Value/Class Appeal',
    key: 'fileAnAppeal',
    url: '/fileAnAppeal',
    icon: 'stack',
    seasonRestriction: '20'
  },
  // {
  //   title: 'File Batch Value Appeals',
  //   key: 'fileBatchValueAppeals',
  //   url: '/fileBatchValueAppeals',
  //   icon: 'stack',
  //   seasonRestriction: '20'
  // },
  {
    title: 'File a Deduction Appeal',
    key: 'fileADeductionAppeal',
    url: '/fileADeductionAppeal',
    icon: 'stack',
  },
  {
    title: 'File an Added/Omitted Appeal',
    key: 'fileAnAddedOmittedAppeal',
    url: '/fileAnAddedOmittedAppeal',
    icon: 'stack',
    seasonRestriction: '10'
  },
  {
    title: 'Appeal Management',
    key: 'searchAppeals',
    url: '/searchAppeals',
    icon: 'search',
  },
  {
    title: 'A/O Appeal Management',
    key: 'searchAOAppeals',
    url: '/searchAOAppeals',
    icon: 'search',
  },
  {
    title: 'Account Info',
    key: 'accountInfo',
    url: '/accountInfo',
    icon: 'credit-card',
  },
  // {
  //   title: 'Analytics',
  //   key: 'viewMetrics',
  //   icon: 'stats-bars',
  //   children: [
  //     {
  //       key: 'appealSummary',
  //       title: 'Summary of Appeals',
  //       url: '/appealsSummary',
  //     },
  //     {
  //       key: 'netValueAtRisk',
  //       title: 'Net Value at Risk',
  //       url: '/netValueAtRisk',
  //     },
  //     {
  //       key: 'appealsByMunicipality',
  //       title: 'Appeals by Municipality',
  //       url: '/appealsByMunicipality',
  //     },
  //   ],
  // },
  // {
  //   title: 'Create Hearing Schedule',
  //   key: 'createHearingSchedule',
  //   url: '/createHearingSchedule',
  //   icon: 'clock',
  // },
  // {
  //   title: 'Super Admin',
  //   key: 'viewSuperAdminPanel',
  //   url: '/createHearingSchedule',
  //   icon: '',
  // },
]
