export default [
  {
    title: 'File a Value/Class Appeal',
    key: 'fileAnAppeal',
    url: '/fileAnAppeal',
    icon: 'stack',
  },
  {
    title: 'File Batch Value Appeals',
    key: 'fileBatchValueAppeals',
    url: '/fileBatchValueAppeals',
    icon: 'stack',
    seasonRestriction: '20'
  },
  {
    title: 'File a Deduction Appeal',
    key: 'fileADeductionAppeal',
    url: '/fileADeductionAppeal',
    icon: 'stack',
  },
  {
    title: 'File an Added/Omitted Appeal',
    key: 'fileAnAddedOmittedAppeal',
    url: '/fileAnAddedOmittedAppeal',
    icon: 'stack',
  },
  {
    title: 'Appeal Management',
    key: 'searchAppeals',
    url: '/searchAppeals',
    icon: 'search',
  },
  {
    title: 'A/O Appeal Management',
    key: 'searchAOAppeals',
    url: '/searchAOAppeals',
    icon: 'search',
  },
  {
    title: 'Analytics',
    key: 'viewMetrics',
    icon: 'stats-bars',
    children: [
      // {
      //   key: 'appealSummary',
      //   title: 'Summary of Appeals',
      //   url: '/appealsSummary',
      // },
      // {
      //   key: 'netValueAtRisk',
      //   title: 'Net Value at Risk',
      //   url: '/netValueAtRisk',
      // },
      // {
      //   key: 'appealsByMunicipality',
      //   title: 'Appeals by Municipality',
      //   url: '/appealsByMunicipality',
      // },
      {
        key: 'reports',
        title: 'Reports',
        url: '/reports',
      },
    ],
  },
  {
    title: 'Create Hearing Schedule',
    key: 'createHearingSchedule',
    url: '/createHearingSchedule',
    icon: 'clock',
  },
  {
    title: 'Judgments',
    key: 'bulkJudgments',
    url: '/bulkJudgments',
    icon: 'hammer2',
  },
  {
    title: 'Account Info',
    key: 'accountInfo',
    url: '/accountInfo',
    icon: 'credit-card',
  },
  // {
  //   title: 'User Admin',
  //   key: 'userAdmin',
  //   url: '/userAdmin',
  //   icon: 'users',
  // },

  {
    title: 'County Admin',
    key: 'configureCounty',
    url: '/configureCounty',
    icon: 'calendar',
  },
  // {
  //   title: 'Super Admin',
  //   key: 'viewSuperAdminPanel',
  //   url: '/createHearingSchedule',
  //   icon: '',
  // },
]
