
export type AppealStatus_WorkInProgress = "Work In Progress";
export type AppealStatus_ReadyForBoard = "Ready For Board";
export type AppealStatus_Stipulated = "Stipulated";
export type AppealStatus_JudgmentIssued = "Judgment Issued";
export type AppealStatus_Withdrawn = "Withdrawn";
export type AppealStatus = AppealStatus_WorkInProgress | AppealStatus_ReadyForBoard | AppealStatus_Stipulated |
    AppealStatus_JudgmentIssued | AppealStatus_Withdrawn;

export type ContactElements = {
    notify?: boolean,
    firstName?: string, //contains full name
    address?: string,
    city?: string //contains city, state
    zipCode?: string,
    emailAddress?: string,
    dayTimePhone?: string, //assumed to be cell phone for SMS
}

export type MainContact = ContactElements & {
  mainEmailCheckbox: boolean,
  mainPhoneCheckbox: boolean
}

export type AdditionalContact = ContactElements & {
  isAdditionalContact?: boolean,
  aCLawyerLicense?: string
  contactEmailCheckbox?: boolean,
  contactPhoneCheckbox?: boolean
}
export type AuthorizedAttorneyContact = ContactElements & {
  isAuthorizedAttorneyContact?: boolean
  authorizedAttorneyEmailCheckbox?: boolean,
  authorizedAttorneyPhoneCheckbox?: boolean
}
export type ThirdPartyContact = ContactElements & {
  isThirdPartyContact?: boolean
  thirdPartyEmailCheckbox?: boolean,
  thirdPartyPhoneCheckbox?: boolean
}

export type SolicitorContact = ContactElements & {
  isActive: boolean
}

export type ContactDefaults = {
  contact: {
    isLawyer: boolean,
    emailAddress: string,
    notifyBySMS: boolean,
    dayTimePhone: string,
    notifyByEmail: boolean
  },
  additionalContact: AdditionalContact,
  solicitorContact: SolicitorContact
}

export type PropertyClassification = {
    key: string,
    name: string
}

export type ClassificationAppeal = {
    appealing: boolean,
    currentClass: PropertyClassification,
    requestedClass: PropertyClassification
}

export type ScanDenial = {
  deferred: boolean,
  fileName: string,
  url: string
}


export type NewAppeal = {
    appealYear: number,
    appVersion: string,
    classification: ClassificationAppeal,
    additionalContact: AdditionalContact,
    authorizedAttorneyContact: AuthorizedAttorneyContact,
    contact: MainContact,
    county: County,
    creatorObj: UserConfiguration,
    thirdPartyContact: ThirdPartyContact,
    creditCardPayment: CreditCardPayment,
    crossAppeal: CrossAppeal,
    deduction: DeductionAppeal
    deleted: boolean,
    entryMethod: EntryMethod,
    entryPaymentMethod: PaymentMethod
    fees: AppealFees
    filingDescription: string,
    geolocation: object,
    hearingDate: string,
    hearingLocation: string,
    hearingType: HearingType,
    improvementRecord: PropertyImprovement,
    judgment?: null,
    municipality: Municipality,
    notes: string,
    property: PropertyRecord,
    receiptId: string,
    scanDenial?: ScanDenial,
    stipulation?: object,
    tenAppeal: boolean,
    tenRecords: [AddOmitRecord],
    valuation: ValuationAppeal,
    withdrawn?: boolean
}

export type AppealRecord = NewAppeal & {
  appealNumber: string,
  database_id: string,
  status: AppealStatus,
  submitDate: string,
}

export type PropertyImprovement = {
    requestedAdditionAbatement? : number,
    requestedAdditionAmount? : number,
    requestedAdditionImprovement? : number,
    requestedAdditionLand? : number,
    requestedAdditionDate? : string,
    requestedAdditionMonth? : number,
    originalRecord?: AddOmitRecord,
    propertyClass: PropertyClassification,
    listType: string,
}

export type PropertyRecord = {
    address: string,
    blq: string,
    cityState: string,
    classification: PropertyClassification,
    currentAssessment: Assessment,
    key: string,
    lotSize: string,
    owner: string,
    purchaseDate: string,
    purchasePrice: number,
    relevantProperty: string,
    tenData: [AddOmitRecord],
    zip: string
}

export type Assessment = {
    abatement: number,
    buildingImprovement: number,
    land: number,
    total: number
}

export type AddOmitRecord = {
    additionAbatement: number,
    additionImprovement: number,
    additionLand: number,
    blq: string,
    completionDate: string,
    listType: string,
    totalMonthsAssessed: string,
    type: string
}

export type AppealFees = {
    convenienceFee: number,
    deductionExemptionFee: number,
    propertyClassificationFee: number,
    propertyValuationFee: number,
    total: number
}

export type TaxAdministrator = {
  name: string,
  title: string
}

export type CountyCommissioner = {
  name: string,
  title: string
}

export type TaxBoard = {
  name: string,
  address: string[]
}

export type County = {
    key: string,
    label: string
    taxAdministrator?: TaxAdministrator,
    taxBoard?: TaxBoard
}

export type CountyDefinition = County & {
  active:boolean,
  municipalities: Municipality[]
}

export type CreditCardPayment = {
    paidAppeal: boolean,
    transId?: string,
    authCode?: string,
    refId?: string,
    paymentAmount?:number
    transactionResponse?: CreditCardTransactionResponse
}

export type CreditCardTransactionResponse = {
    SupplementalDataQualificationIndicator: number,
    accountNumber: string,
    accountType: string,
    authCode: string,
    avsResultCode: string,
    cavvResultCode: string,
    cvvResultCode: string,
    messages: [CreditCardTransactionMessage],
    networkTransId: string,
    responseCode: string,
    testRequest: string,
    transId: string
}

export type CreditCardTransactionMessage = {
    code: string,
    description: string
}

export type CrossAppeal = {
    isCrossAppeal: boolean
    isCrossedAppeal?: boolean,
    otherAppeal?: string
}

export type DeductionAppeal = {
    abatement: boolean,
    disabledVeteranExemption: boolean,
    seniorCitizenOrDisabledPerson: boolean,
    veteranDeduction: boolean
}

export enum EntryMethod {
  ONLINE = "ONLINE",
  PAPER = "PAPER"
}

export enum HearingType {
  IN_PERSON = "InPerson",
  SUMMARY = "Summary",
  VIRTUAL = "Virtual"
}

export type Municipality = {
    key: string,
    label: string
}

export type Appeal = {
    _DATABASE_ID: string,
    _APPEAL_NUMBER: string,
    _UUID: string,
    APPEAL: AppealRecord,
    JUDGEMENT: JudgementRecord,
    STIPULATION: StipulationRecord
}

export type PaymentMethod_NONE = "None";
export type PaymentMethod_CASH = "Cash";
export type PaymentMethod_CHECK = "Check";
export type PaymentMethod_MONEY_ORDER = "Money Order";
export type PaymentMethod_CARD_PRESENT = "Card Present";
export type PaymentMethod = PaymentMethod_CASH | PaymentMethod_NONE | PaymentMethod_CHECK |
    PaymentMethod_MONEY_ORDER | PaymentMethod_CARD_PRESENT;

export type LocalError = {
  name: string,
  message: string
}

export type UserConfiguration = {
    _UUID?: string,
    Created?: string,
    Group?: string,
    Email?: string,
    Fullname?: string,
    Phone?: string,
    County?: County,
    Municipalities?: Array<Municipality>
}

export type ValuationAppeal = {
    appealing: boolean,
    purchaseDate?: string,
    purchasePrice?: number,
    reason?: string,
    requestedTotal?: number,
    taxCourtPending?: boolean
}

export type StipulationRecord = {
    assessorObj: UserConfiguration,
    basisForSettlement: string,
    requestedAdditionMonth: number
    status: StipulationStatus,
    stipAmount: StipulationAmount,
    propertyClass?: PropertyClassification,
    fileName?: string,
    stipFile?: string
}

export type StipulationAmount = {
    landAmount: number,
    buildingAmount: number,
    abatementAmount: number,
    total: number
}

export type JudgementRecord = {
    judgementResult: JudgementResult,
    judgementResultTwo: JudgementResult,
    judgementExplanation: string,
    judgementPropertyClass: PropertyClassification,
    presentSelected: [string],
    judgementAddedDate: string,
    judgementMailDate: string,
    judgementAmount: JudgementAmount
}

export type JudgementResult = {
    key: string,
    label: string
}

export type JudgementAmount = {
    landAmount: number,
    buildingAmount: number,
    abatementAmount: number,
    monthAmount?: number,
    prTotal?: number,
    total: number
}

export type UserActivity = {
  id: string,
  username: string,
  time: string,
  data?: object,
  appeal?: AppealRecord,
  stage: number,
  button: string
}

export type NewAppealRequest = {
  appeal: NewAppeal,
  count: number,
  appealYear: number
}

export type AddAppealEvidenceRequest = {
  evidence: AppealEvidenceRequest
}

export type AppealEvidenceRequest = {
  "title": string,
  "fileName": string,
  "fileUrl": string,
  "assessor": boolean,
}

export type AppealEvidence = AppealEvidenceRequest & {
  "_EID": string,
  "_APPEAL_NUMBER": string,
  "_UUID": string
}

export type AddAppealComparableRequest = {
  comparable: AppealComparableRequest
}

export type AppealComparableRequest = {
  qual: string,
  address: string,
  salePrice: string | number,
  saleDate: string,
  assessor: boolean
}

export type AppealComparable = AppealComparableRequest & {
  "_CID": string,
  "_APPEAL_NUMBER": string,
  "_UUID": string
}

export enum StipulationStatus {
  "PROPOSED" = "PROPOSED",
  "APPELLANT_APPROVED" = "APPELLANTAPPROVED",
  "APPELLANT_REJECTED" = "APPELLANTREJECTED",
  "SOLICITOR_APPROVED" = "SOLICITORAPPROVED",
  "SOLICITOR_REJECTED" = "SOLICITORREJECTED",
}

export type SelectOption = {
  label: string,
  value: string
}

export type AppealSeason = {
  start?: string,
  end?: string,
}

export type MunicipalityAppealSeason = AppealSeason & {
  municipalityKey: string
}


export enum AppealType  {
  "VALUE_CLASS" = "Value/class",
  "DEDUCTION" = "Deduction",
  "ADD_OMIT" = "Addition/omission",
};

export type SeasonConfiguration = {
  season: AppealSeason,
  judgmentSinceFilter?: string,
  municipalityOverrides: MunicipalityAppealSeason[]
}

export type CountyTaxAdministration = {
  taxBoard: TaxBoard,
  taxAdministrator: TaxAdministrator,
  countyCommissioners: CountyCommissioner[],
  meetingRooms: string[],
}

export type CountyConfigurationResponse = {
  countyConfiguration: CountyConfiguration,
}

export type CountyConfiguration = {
  countyTaxAdministration: CountyTaxAdministration,
  valueClassSeason?: SeasonConfiguration,
  addOmitSeason?: SeasonConfiguration,
}

export enum LoadStatus {
  NOT_LOADED = "not loaded",
  IN_PROGRESS = "in progress",
  SUCCESS = "success",
  FAILED = "failed"
};

export enum SaveStatus {
  IDLE = "idle",
  IN_PROGRESS = "in progress",
  SUCCESS = "success",
  FAILED = "failed"
};

export interface FieldData<Type> {
  touched?: boolean,
  validating?: boolean,
  validated?: boolean,
  value?: Type,
  errors?: string[],
  warnings?: string[],
  name: string[],
}
