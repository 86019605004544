import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Layout } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { selectMenuData, setLayoutState, setMenuData } from '../../../../ducks/app';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { default as stdMenuData } from './menuData/menuData';
import { default as assessorMenuData } from './menuData/assessorMenuData';
import { default as ccMenuData } from './menuData/ccMenuData';
import { default as solicitorMenuData } from './menuData/solicitorMenuData';
import { default as caMenuData } from './menuData/caMenuData';
import { default as ccommissionerMenuData} from './menuData/ccommMenuData';
import {
  ASSESSOR,
  COUNTY_ADMIN,
  COUNTY_CLERK,
  COUNTY_COMMISSIONER,
  SOLICITOR
} from '../../../../pages/DefaultPages/common/UserRoles';
//import { getActiveCountiesForAppealType } from '../../../../pages/DefaultPages/common/CountyUtils';
import './style.scss'
import {AppealType} from "../../../../pages/DefaultPages/common/constants";
import {getDefaultPath} from "../../../../routes/routes";

const { Sider } = Layout
const SubMenu = Menu.SubMenu
const Divider = Menu.Divider

const MenuLeft = ({ isMobile }) => {
  const pathname = useLocation().pathname;
  const settingsOpened = useSelector( state => state.app.layoutState.settingsOpened);
  const theme = useSelector( state => state.app.layoutState.theme);
  const userState = useSelector( state => state.app.userState );
  const [selectedKeys, setSelectedKeys] = useState('');
  const [openKeys, setOpenKeys] = useState(['']);
  const [appealDetail, setAppealDetail] = useState(false);
  const dispatch = useDispatch();

  const getLeftMenuIcon = name => name ? (<span className={`icmn icmn-${name} menuLeft__icon`}></span>) : '';

  const setMenuDataForUser = (userState) => {
    const {userGroups, county, municipalities} = userState;
    if (!userState.aatLoaded) {
      return;
    }
    let allMenuData = stdMenuData;
    if( userGroups.includes(COUNTY_ADMIN) ) {
      allMenuData = caMenuData;
    }
    if ( userGroups.includes(COUNTY_CLERK) ) {
      allMenuData = ccMenuData;
    }
    if ( userGroups.includes(ASSESSOR) ) {
      allMenuData = assessorMenuData;
    }
    if ( userGroups.includes(SOLICITOR) ) {
      allMenuData = solicitorMenuData;
    }
    if (userGroups.includes(COUNTY_COMMISSIONER)) {
      allMenuData = ccommissionerMenuData;
    }
    // const countiesForAddOmit = getActiveCountiesForAppealType(AppealType.ADD_OMIT, county, municipalities);
    // if (countiesForValue.length === 0) {
    //   allMenuData = allMenuData.filter(m => m.seasonRestriction !== "10");
    // }
    // const countiesForValue = getActiveCountiesForAppealType(AppealType.VALUE_CLASS, county, municipalities);
    // if (countiesFor20.length === 0) {
    //   allMenuData = allMenuData.filter(m => m.seasonRestriction !== "20");
    // }
    dispatch(setMenuData(allMenuData));
  };


  useEffect(() => {
    if (userState) {
      setMenuDataForUser(userState);
    }
  }, [userState]);

  const menuData = useSelector(selectMenuData);

  useEffect(() => {
    if (menuData) {
      getActiveMenuItem(menuData);
    }
  }, [menuData, pathname])

  const handleClick = e => {
    if (isMobile) {
      // collapse menu on isMobile state
      dispatch(setLayoutState({ menuMobileOpened: false }));
    };
    if (e.key === 'settings') {
      // prevent click and toggle settings block on theme settings link
      dispatch(setLayoutState({ settingsOpened: !settingsOpened }))
      return;
    };
    // set current selected keys
    setSelectedKeys(e.key);
  }

  const onOpenChange = openKeys => {
    setOpenKeys(openKeys);
  }

  function getPath(data, id, parents = []) {
    let items = data.reduce(
      (result, entry) => {
        if (result.length) {
          return result
        } else if (entry.url === id) {
          return [entry].concat(parents)
        } else if (entry.children) {
          let nested = getPath(entry.children, id, [entry].concat(parents))
          return nested.length ? nested : result
        }
        return result;
      },
      [],
    );
    //return items.length > 0 ? items : false
    return items;
  }

  const getActiveMenuItem = items => {
    //let [activeMenuItem, ...path] = getPath(items, !selectedKeys ? pathname : selectedKeys);
    let [activeMenuItem, ...path] = getPath(items, pathname === "/" ? getDefaultPath() : pathname);
    setSelectedKeys(activeMenuItem ? activeMenuItem.key : '');
    setOpenKeys(activeMenuItem ? path.map(entry => entry.key) : []);
  }

  function generateMenuPartitions(items) {
    return items.map(menuItem => {
      if (menuItem.children) {
        return {
          key: menuItem.key,
          label: menuItem.title,
          icon: getLeftMenuIcon(menuItem.icon),
          children: generateMenuPartitions(menuItem.children)
        }
      }
      return generateMenuItem(menuItem);
    })
  }

  function generateMenuItem(item) {
    const { key, title, url, icon, disabled } = item;
    return item.divider ? { type: 'divider' }
       : item.url ? {
          key,
          disabled,
          icon: getLeftMenuIcon(icon),
          label: (
            <Link
              to={url}
              onClick={
                undefined
              }
            >
              <span className="menuLeft__item-title">{title}</span>
            </Link>
          )
        }
      : {key, disabled, icon: getLeftMenuIcon(icon), title};
  }

  useEffect(() => {
    let appealDetail = pathname.includes("/appeal/");
    if (appealDetail) {
      setAppealDetail(true);
    }
    getActiveMenuItem(menuData)
  }, []);

    const menuItems = generateMenuPartitions(menuData);
    menuItems.push({key:'help', icon:getLeftMenuIcon('info'), label:(
        <a href="https://brttechnologies.com/appeal%20support" target="_blank" rel="noopener noreferrer">Appeal Instructions</a>
      )});
    const paramsMobile = {
      width: 256,
      collapsible: false,
      collapsed: false,
    };
    const paramsDesktop = {
      width: appealDetail ? 0 : 256,
      // collapsible: true,
      // collapsed: collapsed,
      // onCollapse: this.onCollapse,
      breakpoint: 'lg',
    };
    const params = isMobile ? paramsMobile : paramsDesktop;

    if(appealDetail){
      return (<div></div>);
    }

    return (
      <Sider {...params} className="menuLeft">
        <div className="menuLeft__logo">
          {params.collapsed ? (
            <div className="menuLeft__logoContainer menuLeft__logoContainer--collapsed">
              <img src="resources/images/brt/njappeal157x.png" alt="" />
            </div>
          ) : (
            <div className="menuLeft__logoContainer">
              <img
                src="resources/images/brt/mynjbackdrop3.jpg"
                height="200"
                width="230"
                alt=""
              />
            </div>
          )}
        </div>
        <Scrollbars
          autoHide
          style={{ height: isMobile ? 'calc(100vh - 64px)' : 'calc(100vh - 112px)' }}
        >
          <Menu
            theme={theme}
            onClick={handleClick}
            selectedKeys={[selectedKeys]}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            className="menuLeft__navigation"
            items={menuItems}
          />
      </Scrollbars>
    </Sider>
  );
}

export default MenuLeft;
