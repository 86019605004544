import {countyDefinitions} from './CountyDefinitions';
import dayjs from "dayjs";
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/New_York");
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

import {AppealType} from "./constants";

export const getActiveAppealYear = (county, appealType) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  if (county.key === '03') {
    if (appealType === AppealType.ADD_OMIT) {
      if (now.getMonth() < 9) {
        return currentYear - 1;
      } else {
        return currentYear;
      }
    } else {
      if (now.getMonth() > 10) {
        return currentYear + 1;
      } else {
        return currentYear;
      }
    }
  }
  //   //TODO fix this to be April 1 if not a weekend
  //   if ((now.getMonth() === 3 && now.getDate() > 3) || now.getMonth() > 3) {
  //     return currentYear + 1;
  //   }
  //   return currentYear;
  // } else {
  //   if (appealType === AppealType.ADD_OMIT) {
  //     if (now.getMonth() < 9) {
  //       return currentYear - 1;
  //     } else {
  //       return currentYear;
  //     }
  //   }
  //   if (now.getMonth() < 1) {
  //     return currentYear - 1;
  //   }
  //
  // }
  return currentYear;
}
