import { configureStore } from '@reduxjs/toolkit';
import app from './app';
import newAppeal from './newAppeal';
import creditCard from "./creditCard";
import appealDetail from "./appealDetail";
import modIV from './modIV';
import appealList from "./appealList";
import admin from "./admin";

export const store = configureStore( {
  reducer: {
    app,
    newAppeal,
    creditCard,
    appealDetail,
    modIV,
    appealList,
    admin,
  }
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
