import {StipulationStatus} from "./constants";

const getAsInteger = value => (
  !value ? 0 : Number.isInteger(value) ? value : Number.parseInt(value)
);
export const calculateAssessmentTotalWithFraction = (propertyClass, land, improvement, abatement, months) => {
  const landNum = getAsInteger(land);
  const improvementNum = getAsInteger(improvement);
  const abatementNum = getAsInteger(abatement);
  const monthsNum = getAsInteger(months) || 12;
  if (shouldUseFractionalTaxation(propertyClass)) {
    const fractionTaxed = improvementNum / 10000;
    return (landNum * fractionTaxed - abatementNum) * monthsNum / 12;
  }
  return (landNum + improvementNum - abatementNum) * monthsNum / 12;
}
export const calculateAssessmentTotal = (propertyClass, land, improvement, abatement, months) =>
   Math.round(calculateAssessmentTotalWithFraction(propertyClass, land, improvement, abatement, months));

export const shouldUseFractionalTaxation = propertyClass => propertyClass?.key === '6A' || propertyClass?.key === '6B'

export const appealHasApprovedStip = stipulation => stipulation?.status === StipulationStatus.SOLICITOR_APPROVED ||
  stipulation?.status === StipulationStatus.APPELLANT_APPROVED

export const getDefaultJudgmentAmount = appeal => {
  let amounts = {};
  if (appeal) {
    if (appeal.JUDGEMENT) {
      amounts = {
        landAmount: appeal.JUDGEMENT.judgementAmount.landAmount,
        buildingAmount: appeal.JUDGEMENT.judgementAmount.buildingAmount,
        abatementAmount: appeal.JUDGEMENT.judgementAmount.abatementAmount,
        total: appeal.JUDGEMENT.judgementAmount.total,
      };
      if (appeal.APPEAL.tenAppeal) {
        amounts.monthAmount = appeal.JUDGEMENT.judgementAmount.monthAmount;
        amounts.prTotal = appeal.JUDGEMENT.judgementAmount.prTotal;
      }
    } else if (appealHasApprovedStip(appeal.STIPULATION)) {
      amounts = {
        landAmount: appeal.STIPULATION.stipAmount.landAmount,
        buildingAmount: appeal.STIPULATION.stipAmount.buildingAmount,
        abatementAmount: appeal.STIPULATION.stipAmount.abatementAmount,
        total: appeal.STIPULATION.stipAmount.total,
      }
      if (appeal.APPEAL.tenAppeal) {
        const mAmount = Number(appeal.STIPULATION.requestedAdditionMonth);
        amounts.monthAmount = appeal.STIPULATION.requestedAdditionMonth;
        amounts.prTotal = Number(Number(appeal.STIPULATION.stipAmount.total) * mAmount / 12).toFixed(0);
      }
    } else if (appeal.APPEAL.tenAppeal) {
      if (appeal.APPEAL.improvementRecord.originalRecord) {
        const orTotal = calculateAssessmentTotal(
          appeal.APPEAL.improvementRecord.originalRecord.propertyClass,
          appeal.APPEAL.improvementRecord.originalRecord.additionImprovement,
          appeal.APPEAL.improvementRecord.originalRecord.additionLand,
          appeal.APPEAL.improvementRecord.originalRecord.additionAbatement
        );
        const orPrTotal = calculateAssessmentTotal(
          appeal.APPEAL.improvementRecord.originalRecord.propertyClass,
          appeal.APPEAL.improvementRecord.originalRecord.additionImprovement,
          appeal.APPEAL.improvementRecord.originalRecord.additionLand,
          appeal.APPEAL.improvementRecord.originalRecord.additionAbatement,
          appeal.APPEAL.improvementRecord.originalRecord.totalMonthsAssessed
        );
        amounts = {
          landAmount: appeal.APPEAL.improvementRecord.originalRecord.additionLand,
          buildingAmount: appeal.APPEAL.improvementRecord.originalRecord.additionImprovement,
          abatementAmount: appeal.APPEAL.improvementRecord.originalRecord.additionAbatement,
          monthAmount: appeal.APPEAL.improvementRecord.originalRecord.totalMonthsAssessed,
          prTotal: orPrTotal,
          total: orTotal,
        };
      } else {
        const addTotal = calculateAssessmentTotal(
          appeal.APPEAL.improvementRecord.propertyClass,
          appeal.APPEAL.improvementRecord.requestedAdditionImprovement,
          appeal.APPEAL.improvementRecord.requestedAdditionLand,
          appeal.APPEAL.improvementRecord.requestedAdditionAbatement
        );
        const addPrTotal = calculateAssessmentTotal(
          appeal.APPEAL.improvementRecord.propertyClass,
          appeal.APPEAL.improvementRecord.requestedAdditionImprovement,
          appeal.APPEAL.improvementRecord.requestedAdditionLand,
          appeal.APPEAL.improvementRecord.requestedAdditionAbatement,
          appeal.APPEAL.improvementRecord.requestedAdditionMonth
        );
        amounts = {
          landAmount: appeal.APPEAL.improvementRecord.requestedAdditionLand,
          buildingAmount: appeal.APPEAL.improvementRecord.requestedAdditionImprovement,
          abatementAmount: appeal.APPEAL.improvementRecord.requestedAdditionAbatement,
          monthAmount: appeal.APPEAL.improvementRecord.requestedAdditionMonth,
          prTotal: addPrTotal,
          total: addTotal,
        };
      }
    } else {
      amounts = {
        landAmount: appeal.APPEAL.property.currentAssessment.land,
        buildingAmount: appeal.APPEAL.property.currentAssessment.buildingImprovement,
        abatementAmount: appeal.APPEAL.property.currentAssessment.abatement,
        total: appeal.APPEAL.property.currentAssessment.total,
      }
    }
  }
  return amounts;
}

export const getDefaultJudgmentPropertyClass = appeal => (
  appeal?.JUDGEMENT?.judgementPropertyClass ||
  (appealHasApprovedStip(appeal?.STIPULATION) ? appeal.STIPULATION?.propertyClass : null ) ||
  appeal?.APPEAL.improvementRecord?.propertyClass ||
  appeal?.APPEAL.property.classification
);